/* src/components/FeatureCard.css */

/* Feature Card */
.feature-card {
  background-color: #1a1a1a; /* Dark gray background */
  width: 320px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  opacity: 0; /* For animation */
}

.feature-card[data-aos] {
  opacity: 1; /* AOS handles the animation */
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
}

.feature-card:hover .feature-image img {
  transform: scale(1.1);
}

/* Feature Image */
.feature-image {
  overflow: hidden;
}

.feature-image img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

/* Feature Content */
.feature-content {
  padding: 30px 20px;
  text-align: center;
}

.feature-content h2 {
  font-size: 1.8em;
  color: #00ff00; /* Neon green headings */
  margin-bottom: 20px;
}

.feature-content p {
  font-size: 1em;
  color: #cccccc; /* Light gray text */
  margin-bottom: 30px;
  line-height: 1.6;
}

/* Feature Button */
.feature-button {
  display: inline-block;
  padding: 12px 30px;
  background: linear-gradient(135deg, #00ff00, #00e600); /* Neon green gradient */
  color: #000000; /* Black text */
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  box-shadow: 0 8px 15px rgba(0, 255, 0, 0.3);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.feature-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: opacity 0.3s, transform 0.3s;
}

.feature-button:hover::before {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.feature-button:hover {
  box-shadow: 0 15px 30px rgba(0, 255, 0, 0.4);
  transform: translateY(-5px);
}
