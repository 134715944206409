/* src/pages/LandingPage.css */

/* Reset some basic styles */
body, html, .landing-page {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--black); /* Black background */
    color: var(--white); /* White text for better contrast */
    overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Define CSS Variables for Consistent Color Management */
:root {
    /* Blue Color Variables */
    --primary-blue: #1E90FF;       /* Dodger Blue */
    --dark-blue: #00008B;          /* Dark Blue */
    --accent-blue: #00BFFF;        /* Deep Sky Blue */
    --light-blue: #ADD8E6;         /* Light Blue */

    /* Red Color Variables for Accents */
    --accent-red: #FF4500;         /* OrangeRed */
    --highlight-red: #FF6347;      /* Tomato Red */

    /* Neutral Colors */
    --white: #FFFFFF;
    --black: #000000;
    --dark-gray: #333333;

    /* Shadow Colors */
    --shadow-blue: rgba(30, 144, 255, 0.3);        /* Dodger Blue shadow */
    --shadow-dark-blue: rgba(0, 0, 139, 0.5);      /* Dark Blue shadow */

    /* Overlay Opacity */
    --overlay-opacity: 0.2;
}

/* Navigation Bar */
header {
    background-color: var(--black); /* Black navbar */
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 4px var(--shadow-blue); /* Blue shadow */
    animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

nav a {
    color: var(--primary-blue); /* Blue text */
    padding: 14px 20px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    position: relative;
    font-weight: bold; /* Added for emphasis */
    font-size: 1rem;
}

nav a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--primary-blue); /* Blue underline */
    transition: width 0.3s;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
}

nav a:hover::after {
    width: 50%;
}

nav a:hover {
    background-color: var(--primary-blue); /* Blue background on hover */
    color: var(--black); /* Black text on hover for contrast */
    border-radius: 4px;
    transform: translateY(-2px);
}

/* Style the Contact button to match other links */
nav .nav-button {
    background: none;
    border: none;
    color: var(--primary-blue); /* Blue text */
    cursor: pointer;
    padding: 14px 20px;
    font-size: 1rem;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    position: relative;
    font-weight: bold; /* Added for emphasis */
}

nav .nav-button::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--primary-blue); /* Blue underline */
    transition: width 0.3s;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
}

nav .nav-button:hover::after {
    width: 50%;
}

nav .nav-button:hover {
    background-color: var(--primary-blue); /* Blue background on hover */
    color: var(--black); /* Black text on hover for contrast */
    border-radius: 4px;
    transform: translateY(-2px);
}

/* Home Section */
#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center vertically */
    text-align: center; /* Center text */
    min-height: 100vh;
    background-color: var(--black); /* Black background */
    padding-top: 70px; /* Increased to account for fixed navbar */
    padding-bottom: 20px; /* Added padding-bottom */
    animation: fadeIn 1s ease-in-out;
    overflow-y: auto;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Adjust h1 since it contains an image instead of text */
#home h1 {
    /* Removed font-size since it contains an image */
    margin-bottom: 20px;
    color: var(--primary-blue); /* Blue heading */
    animation: popIn 0.8s ease-out forwards;
    opacity: 0;
}

/* Logo Styling */
.ghcache-logo {
    max-width: 300px; /* Default maximum width */
    width: 100%;
    height: auto;
    /* Optional: Add a margin-bottom if needed */
    margin-bottom: 20px;
    /* Ensure the logo scales properly */
    display: block;
}

/* Responsive Logo Adjustments */
@media (max-width: 1024px) {
    .ghcache-logo {
        max-width: 250px;
    }
}

@media (max-width: 768px) {
    .ghcache-logo {
        max-width: 200px;
    }
}

@media (max-width: 600px) {
    .ghcache-logo {
        max-width: 150px;
    }
}

/* Gradient Text Styling */
.gradient-text {
    background: linear-gradient(90deg, var(--primary-blue), var(--dark-blue)); /* Blue gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

/* Animation for Pop-In Effect */
@keyframes popIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Animated Button Styles - Base Styles */
.animated-button {
    margin: 10px;
    padding: 15px 30px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    color: var(--white); /* White text */
    background-color: var(--primary-blue); /* Blue background */
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 4px 6px var(--shadow-blue); /* Blue shadow */
    display: inline-block; /* Ensure buttons are block-level for mobile */
    z-index: 2; /* Place buttons above other elements */
}

.animated-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, var(--overlay-opacity)); /* Light overlay for ripple effect */
    opacity: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: opacity 0.3s, transform 0.3s;
}

.animated-button:hover::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.animated-button:hover {
    background-color: var(--dark-blue); /* Darker Blue on hover */
    transform: translateY(-3px);
}

.animated-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 139, 0.2); /* Reduced shadow on active */
}

/* Buttons Specific Classes */
#home .btn-primary {
    /* Inherits styles from .animated-button */
}

#home .btn-secondary {
    background-color: var(--dark-blue); /* Dark Blue button */
    color: var(--white); /* White text */
}

#home .btn-secondary:hover {
    background-color: var(--accent-blue); /* Accent Blue on hover */
}

/* Features, About, Contact Sections */
section.container {
    padding: 50px 20px;
    background-color: var(--black); /* Black background */
    animation: fadeIn 1.5s ease-in-out;
}

section.container h2 {
    color: var(--primary-blue); /* Blue headings */
    margin-bottom: 20px;
    animation: slideInLeft 1s ease-out;
    font-size: 2rem;
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

section.container p {
    color: var(--white); /* White text */
    line-height: 1.6;
    animation: slideInRight 1s ease-out;
    opacity: 0;
    transform: translateX(50px);
    font-size: 1rem;
}

@keyframes slideInRight {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Features Grid */
.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 40px;
    justify-items: center;
}

/* Feature Card */
.feature-card {
    background-color: var(--black); /* Black background */
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    max-width: 320px;
    box-shadow: 0 15px 30px var(--shadow-blue); /* Blue tint shadow */
    position: relative;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 25px 50px var(--shadow-dark-blue); /* Enhanced blue shadow on hover */
}

.feature-image {
    overflow: hidden;
}

.feature-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.feature-card:hover .feature-image img {
    transform: scale(1.05);
}

.feature-content {
    padding: 30px 20px;
    text-align: center;
}

.feature-content h2 {
    font-size: 1.8rem;
    color: var(--primary-blue); /* Blue headings */
    margin-bottom: 20px;
}

.feature-content p {
    font-size: 1rem;
    color: var(--white); /* White text */
    margin-bottom: 30px;
    line-height: 1.6;
}

.feature-button {
    padding: 12px 30px;
    background: linear-gradient(135deg, var(--primary-blue), var(--dark-blue)); /* Blue gradient */
    color: var(--white);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    box-shadow: 0 8px 15px var(--shadow-blue); /* Blue shadow */
    transition: all 0.3s ease-in-out;
}

.feature-button:hover {
    box-shadow: 0 15px 30px var(--shadow-dark-blue); /* Enhanced blue shadow on hover */
    transform: translateY(-5px);
}

/* About Section */
.about-section {
    padding: 60px 20px;
    background-color: var(--black); /* Black background */
    text-align: center;
}

.about-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--primary-blue); /* Blue headings */
    position: relative;
}

.about-section h2::after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background: var(--primary-blue); /* Blue underline */
    margin: 10px auto 0;
}

.about-section p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: var(--white); /* White text */
    max-width: 800px;
    margin: 0 auto 40px;
}

.about-section h3 {
    font-size: 2rem;
    margin-top: 40px;
    margin-bottom: 30px;
    color: var(--primary-blue); /* Blue headings */
    position: relative;
}

.about-section h3::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background: var(--primary-blue); /* Blue underline */
    margin: 10px auto 0;
}

.values-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.values-list li {
    font-size: 1.1rem;
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;
    color: var(--white); /* White text */
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    max-width: 600px;
}

.values-list li:hover {
    transform: translateX(10px);
}

.values-list .icon {
    font-size: 1.5rem;
    margin-right: 15px;
    color: var(--accent-red); /* Red icons for accents */
}

.values-list li strong {
    color: var(--accent-red); /* Red text for emphasis */
    margin-right: 5px;
}

/* Contact Section */
#contact {
    padding: 60px 20px;
    background-color: var(--black); /* Black background */
    text-align: center;
}

#contact h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--primary-blue); /* Blue headings */
    position: relative;
}

#contact h2::after {
    content: '';
    display: block;
    width: 60px;
    height: 3px;
    background: var(--primary-blue); /* Blue underline */
    margin: 10px auto 0;
}

#contact p {
    font-size: 1.2rem;
    line-height: 1.8;
    color: var(--white); /* White text */
    max-width: 800px;
    margin: 0 auto 40px;
}

/* Fixed Buttons */
.fixed-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1001;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.fixed-buttons .btn {
    padding: 12px 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0 4px 6px var(--shadow-blue); /* Blue shadow */
    position: relative;
    overflow: hidden;
}

.fixed-buttons .btn-success {
    background-color: var(--primary-blue); /* Blue button */
    color: var(--white); /* White text */
}

.fixed-buttons .btn-success::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, var(--overlay-opacity)); /* Light overlay */
    opacity: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: opacity 0.3s, transform 0.3s;
}

.fixed-buttons .btn-success:hover::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.fixed-buttons .btn-success:hover {
    background-color: var(--dark-blue); /* Dark Blue on hover */
    transform: translateY(-3px);
}

.fixed-buttons .btn-danger {
    background-color: var(--accent-red); /* Red accent button */
    color: var(--white); /* White text */
}

.fixed-buttons .btn-danger::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, var(--overlay-opacity)); /* Light overlay */
    opacity: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: opacity 0.3s, transform 0.3s;
}

.fixed-buttons .btn-danger:hover::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.fixed-buttons .btn-danger:hover {
    background-color: var(--dark-blue); /* Dark Blue on hover */
    transform: translateY(-3px);
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85); /* Black overlay with slight opacity */
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeInOverlay 0.5s ease-in-out;
    padding: 10px; /* Added padding for smaller screens */
}

@keyframes fadeInOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Modal Content */
.modal-content {
    background: var(--black); /* Black background */
    padding: 40px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 5px 15px var(--shadow-dark-blue); /* Blue shadow */
    position: relative;
    color: var(--white); /* White text */
    animation: popInModal 0.5s ease-out;
    max-height: 90vh;
    overflow-y: auto; /* Ensure scroll on smaller screens */
}

@keyframes popInModal {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Close Button */
.modal-content .close {
    position: absolute;
    top: 15px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--primary-blue); /* Blue close icon */
    transition: color 0.3s, transform 0.2s;
}

.modal-content .close:hover,
.modal-content .close:focus {
    color: var(--highlight-red); /* Red accent on hover */
    transform: rotate(90deg);
}

/* Form Styles */
.form-group {
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin: 10px 0 5px;
    color: var(--primary-blue); /* Blue labels */
    font-size: 1rem;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
textarea {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 2px solid var(--primary-blue); /* Blue borders */
    border-radius: 4px;
    background-color: var(--dark-gray); /* Dark gray background */
    color: var(--white); /* White text */
    transition: border-color 0.3s, box-shadow 0.3s;
    font-size: 1rem;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
textarea:focus {
    border-color: var(--dark-blue); /* Dark Blue border on focus */
    box-shadow: 0 0 10px rgba(0, 0, 139, 0.5); /* Dark Blue glow */
    outline: none;
}

/* Textarea Resizing */
textarea.form-control {
    resize: vertical;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    /* About Section Responsive Adjustments */
    .about-section h2,
    .about-section h3 {
        font-size: 2rem;
    }

    .about-section p,
    .values-list li {
        font-size: 1rem;
    }

    /* Button adjustments for mobile */
    .animated-button {
        width: 80%;
        max-width: 300px;
        margin: 10px auto;
        padding: 12px 18px; /* Reduced padding for smaller screens */
        font-size: 0.9rem; /* Slightly smaller font */
    }

    /* Features Grid Responsive */
    .features-grid {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 20px;
    }

    .feature-card {
        max-width: 100%;
    }

    /* Ensure buttons are wider and stacked vertically */
    #home button {
        width: 80%; /* Make buttons take up more horizontal space */
        margin: 10px 0; /* Add vertical spacing between buttons */
        display: block; /* Ensure buttons are block-level elements */
    }

    /* Increase tap target sizes for fixed buttons */
    .fixed-buttons .btn {
        padding: 14px 22px; /* Slightly larger padding for easier tapping */
        font-size: 1rem;
    }

    /* Adjust modal content padding */
    .modal-content {
        padding: 20px;
    }

    /* Navigation adjustments for smaller screens */
    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav a, nav .nav-button {
        width: 100%;
        text-align: left;
        padding: 10px 15px;
        font-size: 0.9rem;
    }

    /* Reduce font sizes in home section */
    #home h1 {
        font-size: 2.5rem;
    }

    /* Adjust feature-content padding */
    .feature-content {
        padding: 20px 15px;
    }

    /* Adjust form labels and inputs */
    label {
        font-size: 0.9rem;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="date"],
    textarea {
        font-size: 0.9rem;
    }

    /* Adjust fixed buttons layout */
    .fixed-buttons {
        flex-direction: column;
        right: 10px;
        bottom: 10px;
    }

    /* Further reduce logo size if necessary */
    .ghcache-logo {
        max-width: 200px;
    }
}

@media (max-width: 600px) {
    /* Further adjustments for very small screens */

    /* Adjust modal content padding */
    .modal-content {
        padding: 15px;
    }

    /* Adjust button sizes */
    button.btn {
        padding: 10px;
        font-size: 0.9rem;
    }

    /* Adjust navigation button padding and font size */
    nav .nav-button {
        padding: 10px 15px;
        font-size: 0.9rem;
    }

    /* Adjust home section typography */
    #home h1 {
        font-size: 2rem;
    }

    #home p {
        font-size: 1.2rem;
    }

    /* Adjust animated buttons for mobile */
    .animated-button {
        padding: 12px 18px; /* Reduced padding for smaller screens */
        font-size: 0.9rem; /* Slightly smaller font */
    }

    /* Features Grid Responsive */
    .features-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    /* Ensure buttons are wider and stacked vertically */
    #home button {
        width: 90%; /* Make buttons take up more horizontal space */
        margin: 10px auto;
    }

    /* Increase tap target sizes for fixed buttons */
    .fixed-buttons .btn {
        padding: 14px 22px; /* Slightly larger padding for easier tapping */
        font-size: 1rem;
    }

    /* Further reduce logo size */
    .ghcache-logo {
        max-width: 150px;
    }
}

/* Animation Classes */
.section-animate {
    opacity: 0;
    transform: translateY(20px);
    animation: sectionFadeIn 1s forwards;
}

@keyframes sectionFadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.delay-1 {
    animation-delay: 0.3s;
}

.delay-2 {
    animation-delay: 0.6s;
}

.delay-3 {
    animation-delay: 0.9s;
}

/* Modal Styles */

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85); /* Black overlay with slight opacity */
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeInOverlay 0.5s ease-in-out;
    padding: 10px; /* Added padding for smaller screens */
}

@keyframes fadeInOverlay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Modal Content */
.modal-content {
    background: var(--black); /* Black background */
    padding: 40px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 5px 15px var(--shadow-dark-blue); /* Blue shadow */
    position: relative;
    color: var(--white); /* White text */
    animation: popInModal 0.5s ease-out;
    max-height: 90vh;
    overflow-y: auto; /* Ensure scroll on smaller screens */
}

@keyframes popInModal {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Close Button */
.modal-content .close {
    position: absolute;
    top: 15px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--primary-blue); /* Blue close icon */
    transition: color 0.3s, transform 0.2s;
}

.modal-content .close:hover,
.modal-content .close:focus {
    color: var(--highlight-red); /* Red accent on hover */
    transform: rotate(90deg);
}

/* Form Styles */
.form-group {
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    margin: 10px 0 5px;
    color: var(--primary-blue); /* Blue labels */
    font-size: 1rem;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
textarea {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border: 2px solid var(--primary-blue); /* Blue borders */
    border-radius: 4px;
    background-color: var(--dark-gray); /* Dark gray background */
    color: var(--white); /* White text */
    transition: border-color 0.3s, box-shadow 0.3s;
    font-size: 1rem;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
textarea:focus {
    border-color: var(--dark-blue); /* Dark Blue border on focus */
    box-shadow: 0 0 10px rgba(0, 0, 139, 0.5); /* Dark Blue glow */
    outline: none;
}

/* Textarea Resizing */
textarea.form-control {
    resize: vertical;
}

/* Button Styles */
button.btn {
    padding: 12px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 6px var(--shadow-blue); /* Blue shadow */
}

button.btn-primary {
    background-color: var(--primary-blue); /* Blue button */
    color: var(--white); /* White text */
}

button.btn-primary:hover {
    background-color: var(--dark-blue); /* Darker Blue on hover */
    transform: scale(1.05);
    box-shadow: 0 6px 8px var(--shadow-dark-blue); /* Dark Blue shadow */
}

button.btn-secondary {
    background-color: var(--dark-blue); /* Dark Blue button */
    color: var(--white); /* White text */
}

button.btn-secondary:hover {
    background-color: var(--accent-blue); /* Accent Blue on hover */
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(30, 144, 255, 0.4); /* Accent Blue shadow */
}

/* Response Messages */
.response {
    margin-top: 10px;
    color: var(--primary-blue); /* Blue messages */
    animation: fadeIn 0.5s ease-in-out;
    font-size: 1rem;
}

.response.error {
    color: var(--highlight-red); /* Red accent for errors */
}

/* Typography */
h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

/* Ensure buttons are above any other elements */
.animated-button {
    z-index: 2; /* Place buttons above other elements */
}

/* Accessibility Enhancements */
/* Focus States */
button:focus,
a:focus,
input:focus,
textarea:focus {
    outline: 3px solid var(--accent-red);
    outline-offset: 2px;
}

/* Reduce motion for users who prefer it */
@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important;
        transition: none !important;
    }
}

/* Additional Responsive Enhancements */
@media (min-width: 769px) {
    /* Desktop Specific Styles */

    /* Navigation spacing */
    nav a, nav .nav-button {
        margin: 0 10px;
    }

    /* Home Section */
    #home h1 {
        /* Removed font-size as it's handled by the logo image */
    }

    /* Feature Image Height */
    .feature-image img {
        height: auto; /* Ensure height adjusts based on content */
    }

    /* About Section */
    .about-section p {
        font-size: 1.2rem;
    }

    /* Contact Section */
    #contact p {
        font-size: 1.2rem;
    }
}
