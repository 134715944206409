/* =========================
   1. Reset and Basic Styles
========================= */

/* Reset and basic styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html, .dashboard-page {
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  scroll-behavior: smooth;
}

/* =========================
  2. CSS Variables for Theming
========================= */

:root {
  /* Color Palette */
  --color-background: #2B2B2B; /* Dark Grey */
  --color-surface: linear-gradient(135deg, #3C3C3C, #2B2B2B); /* Metallic Grey Gradient */
  --color-primary: #C0C0C0; /* Silver */
  --color-primary-variant: #A8A8A8; /* Darker Silver */
  --color-secondary: #D3D3D3; /* Light Grey */
  --color-error: #CF6679; /* Can keep or adjust as needed */
  --color-text: #E0E0E0; /* Light Text */
  --color-text-secondary: #B3B3B3;
  --color-border: #555555; /* Grey Border */
  --color-hover: rgba(255, 255, 255, 0.1); /* Subtle Hover */

  /* Spacing */
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-sm: 12px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;

  /* Font Sizes */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;

  /* Shadows */
  --shadow-elevation-1: 0px 1px 3px rgba(0, 0, 0, 0.2);
  --shadow-elevation-2: 0px 4px 6px rgba(0, 0, 0, 0.3);
  --shadow-elevation-3: 0px 10px 20px rgba(0, 0, 0, 0.4);

  /* Transition */
  --transition-duration: 0.3s;
  --transition-ease: ease-in-out;

  /* Z-Index */
  --z-index-modal: 1000;
  --z-index-floating-button: 100;
}

/* =========================
  3. User Profile Container Styles
========================= */

/* User Profile Container */
.user-profile {
  background: var(--color-surface);
  backdrop-filter: blur(10px);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-lg);
  max-width: 700px;
  margin: var(--spacing-xxl) auto;
  color: var(--color-text);
  position: relative; /* Positioning context for absolute elements like BackButton */
  box-shadow: var(--shadow-elevation-2);
  transition: background var(--transition-duration), box-shadow var(--transition-duration);
}

/* Hover Effect */
.user-profile:hover {
  background: linear-gradient(135deg, #4A4A4A, #2B2B2B);
  box-shadow: var(--shadow-elevation-3);
}

/* =========================
  4. Profile Header Styles
========================= */

/* Profile Header */
.profile-header {
  text-align: center;
  margin-bottom: var(--spacing-lg);
  position: relative;
}

/* Header Title */
.profile-header h2 {
  color: var(--color-secondary);
  text-align: center;
  font-size: var(--font-size-xxl);
  margin: 0; /* Remove default margins */
  position: relative;
  padding-top: var(--spacing-sm);
}

/* Decorative Underline */
.profile-header h2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -var(--spacing-xs);
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--color-primary);
  border-radius: var(--border-radius-sm);
}

/* =========================
  5. Profile Form Styles
========================= */

/* Profile Form */
.profile-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  animation: fadeInUp var(--transition-duration) var(--transition-ease);
}

/* Avatar Section */
.avatar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm);
}

.avatar-preview {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid var(--color-primary);
  transition: border-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);
  box-shadow: var(--shadow-elevation-1);
}

.avatar-preview:hover {
  border-color: var(--color-primary-variant);
  transform: scale(1.05);
  box-shadow: var(--shadow-elevation-2);
}

/* Upload Avatar Button */
.upload-avatar-button {
  background: var(--color-primary);
  color: var(--color-text);
  padding: var(--spacing-xs) var(--spacing-sm);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background var(--transition-duration), transform var(--transition-duration);
  font-size: var(--font-size-sm);
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
  box-shadow: var(--shadow-elevation-1);
}

.upload-avatar-button:hover {
  background: var(--color-primary-variant);
  transform: translateY(-2px);
  box-shadow: var(--shadow-elevation-2);
}

/* Form Group Styles */
.form-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxs);
}

.form-group label {
  font-size: var(--font-size-md);
  color: var(--color-secondary);
  display: flex;
  align-items: center;
}

.form-group .required {
  color: var(--color-error); /* Red asterisk for required fields */
  margin-left: 2px;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group textarea {
  padding: var(--spacing-sm);
  border: 2px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: var(--font-size-md);
  transition: border-color var(--transition-duration), box-shadow var(--transition-duration);
}

.form-group input[type="text"]:focus,
.form-group input[type="date"]:focus,
.form-group textarea:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 10px var(--color-primary);
  outline: none;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
  background-color: #3C3C3C; /* Slightly lighter for better readability */
}

/* Error Messages */
.error {
  color: var(--color-error);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xxs);
}

/* Button Styles */
.btn {
  padding: var(--spacing-sm) var(--spacing-md);
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  font-size: var(--font-size-md);
  font-weight: bold;
  transition: background var(--transition-duration), transform var(--transition-duration);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xxs);
  box-shadow: var(--shadow-elevation-1);
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-text);
}

.btn-primary:hover {
  background: var(--color-primary-variant);
  transform: translateY(-2px);
  box-shadow: var(--shadow-elevation-2);
}

.btn-secondary {
  background: var(--color-error);
  color: var(--color-text);
}

.btn-secondary:hover {
  background: #B33E5C; /* Manually set a darker color */
  transform: translateY(-2px);
  box-shadow: var(--shadow-elevation-2);
}

.save-button {
  align-self: center;
  width: 160px;
  animation: pulse 2s infinite;
}

/* =========================
  6. Loading Styles
========================= */

/* Loading Styles */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: var(--font-size-xl);
  animation: fadeIn var(--transition-duration) var(--transition-ease);
}

/* =========================
  7. Responsive Design
========================= */

@media (max-width: 768px) {
  .user-profile {
    padding: var(--spacing-md);
    margin: var(--spacing-xl) auto;
  }

  .back-button {
    top: var(--spacing-sm);
    left: var(--spacing-sm);
    font-size: var(--font-size-xs);
    padding: var(--spacing-xxs) var(--spacing-xs);
  }

  .profile-header h2 {
    font-size: var(--font-size-lg);
  }

  .avatar-preview {
    width: 100px;
    height: 100px;
  }

  .upload-avatar-button {
    padding: var(--spacing-xxs) var(--spacing-sm);
    font-size: var(--font-size-xs);
  }

  .btn-primary,
  .btn-secondary {
    width: 100%;
  }

  .save-button {
    width: 100%;
  }
}

/* =========================
  8. Animations
========================= */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(192, 192, 192, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0);
  }
}
