.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000; /* Black background */
}

.login-form {
  background-color: #1a1a1a; /* Dark gray background */
  padding: 40px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  color: #ffffff; /* White text */
}

.login-form h2 {
  text-align: center;
  color: #00ff00; /* Green heading */
  margin-bottom: 20px;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
  color: #00ff00; /* Green labels */
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid #00ff00; /* Green borders */
  border-radius: 4px;
  background-color: #333333; /* Dark background for inputs */
  color: #ffffff; /* White text */
  transition: border-color 0.3s;
}

.login-form input:focus {
  border-color: #00e600; /* Darker green on focus */
  outline: none;
}

/* Base button styling */
.login-form .btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-bottom: 10px; /* Space between buttons */
  font-size: 16px;
}

/* Primary button (Login) */
.login-form .btn.btn-primary {
  background-color: #00ff00; /* Green button */
  color: #000000; /* Black text */
}

.login-form .btn.btn-primary:hover {
  background-color: #00e600; /* Darker green on hover */
  transform: scale(1.05);
}

/* Secondary button (Reset Password) */
.login-form .btn.btn-secondary {
  background-color: #008000; /* A slightly darker green */
  color: #ffffff; /* White text */
}

.login-form .btn.btn-secondary:hover {
  background-color: #006400; /* Even darker green on hover */
  transform: scale(1.05);
}

.response {
  margin-top: 10px;
  text-align: center;
  color: #00ff00; /* Green color for success messages */
}

.response.error {
  color: #ff0000; /* Red color for error messages */
}
