/* src/variables.css */

/* =========================
    1. Light Theme Variables
========================= */
:root {
    /* Top Bar Height */
    --top-bar-height: 60px; /* Default height for desktop */
    --top-bar-height-mobile: 50px; /* Reduced height for mobile */

    /* Color Palette - Light Theme */
    --color-background: #FFFFFF;
    --color-surface: #F0F0F0;
    --color-primary: #007BFF;
    --color-primary-variant: #0056b3;
    --color-secondary: #6C757D;
    --color-secondary-variant: #545B62;
    --color-error: #DC3545;
    --color-text: #000000;
    --color-text-secondary: #6C757D;
    --color-border: #CED4DA;
    --color-hover: rgba(0, 0, 0, 0.05);
    --color-shadow: rgba(0, 0, 0, 0.1);

    /* Grey Palette */
    --color-grey: #6c757d; /* Medium Grey */
    --color-grey-hover: #5a6268; /* Darker Grey for Hover */
    --color-grey-active: #545b62; /* Even Darker Grey for Active State */

    /* Spacing */
    --spacing-xxs: 4px;
    --spacing-xs: 8px;
    --spacing-sm: 12px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 48px;

    /* Font Sizes */
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.25rem;
    --font-size-xl: 1.5rem;
    --font-size-xxl: 2rem;

    /* Border Radius */
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 16px;

    /* Shadows */
    --shadow-elevation-1: 0px 1px 3px rgba(0,0,0,0.2);
    --shadow-elevation-2: 0px 4px 6px rgba(0,0,0,0.3);
    --shadow-elevation-3: 0px 10px 20px rgba(0,0,0,0.4);

    /* Transition */
    --transition-duration: 0.3s;
    --transition-ease: ease-in-out;

    /* Z-Index */
    --z-index-top-bar: 1000; /* Top Bar */
    --z-index-modal: 1000;
    --z-index-floating-button: 2000; /* Floating above other elements */
}

/* =========================
    2. Dark Theme Overrides
========================= */
.dark-theme {
    /* Color Palette - Dark Theme */
    --color-background: #121212;
    --color-surface: #1E1E1E;
    --color-primary: #BB86FC;
    --color-primary-variant: #3700B3;
    --color-secondary: #03DAC6;
    --color-secondary-variant: #018786;
    --color-error: #CF6679;
    --color-text: #FFFFFF;
    --color-text-secondary: #B3B3B3;
    --color-border: #2C2C2C;
    --color-hover: rgba(255, 255, 255, 0.1);
    --color-shadow: rgba(0, 0, 0, 0.5);

    /* Grey Palette */
    --color-grey: #6c757d; /* Medium Grey */
    --color-grey-hover: #5a6268; /* Darker Grey for Hover */
    --color-grey-active: #545b62; /* Even Darker Grey for Active State */
}

/* =========================
    3. Mobile Adjustments
========================= */
@media (max-width: 768px) {
    :root {
        --top-bar-height: var(--top-bar-height-mobile);
    }
}
