/* src/components/CommentSection.css */

.comment-section {
  margin-top: var(--spacing-md);
}

.comments-container {
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
  padding-right: var(--spacing-xs); /* Prevents content hiding behind scrollbar */
  margin-bottom: var(--spacing-md);
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  position: relative;
}

/* Custom Scrollbar */
.comments-container::-webkit-scrollbar {
  width: 6px;
}

.comments-container::-webkit-scrollbar-track {
  background: var(--color-scrollbar-track);
  border-radius: var(--border-radius-sm);
}

.comments-container::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollbar-thumb);
  border-radius: var(--border-radius-sm);
}

/* Firefox */
.comments-container {
  scrollbar-width: thin;
  scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar-track);
}

.comments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comment {
  padding: var(--spacing-sm) 0;
  border-bottom: 1px solid var(--color-border);
  transition: background-color var(--transition-duration);
}

.comment:hover {
  background-color: rgba(29, 161, 242, 0.05); /* Light primary color background on hover */
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}

.comment-email {
  font-weight: bold;
  color: var(--color-text);
}

.comment-time {
  font-size: var(--font-size-xs);
  color: var(--color-text-secondary);
}

.comment-content {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  line-height: 1.4;
}

.add-comment-form {
  display: flex;
  gap: var(--spacing-xs);
  margin-top: var(--spacing-sm);
}

.add-comment-form input {
  flex: 1;
  padding: var(--spacing-xs);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  transition: border-color var(--transition-duration), box-shadow var(--transition-duration);
}

.add-comment-form input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.2);
  outline: none;
}

.add-comment-form button {
  padding: var(--spacing-xs) var(--spacing-sm);
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.add-comment-form button:hover {
  background-color: var(--color-primary-dark);
  transform: scale(1.02);
}

.add-comment-form button:disabled {
  background-color: var(--color-disabled);
  cursor: not-allowed;
}

/* Load More Comments Button */
.load-more-comments {
  background-color: var(--color-button);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  cursor: pointer;
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-sm);
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.load-more-comments:hover {
  background-color: var(--color-button-hover);
  transform: scale(1.02);
}

.load-more-comments:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .comments-container {
    max-height: 150px;
  }

  .comment-content {
    font-size: var(--font-size-xs);
  }

  .add-comment-form input {
    font-size: var(--font-size-xs);
  }

  .add-comment-form button {
    font-size: var(--font-size-xs);
  }

  .load-more-comments {
    font-size: var(--font-size-xs);
    padding: var(--spacing-xs) var(--spacing-xs);
  }
}
