/* src/components/UserFeed.css */

.user-feed-page {
    padding: 50px 20px;
    background-color: #1a1a1a;
    color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .user-feed-page h2 {
    color: #00ff00;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  
  .feed-items {
    width: 100%;
    max-width: 800px;
  }
  
  .feed-item {
    background-color: #2a2a2a;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 10px rgba(0, 255, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feed-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 255, 0, 0.3);
  }
  
  .feed-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .feed-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #00ff00;
    margin-right: 15px;
  }
  
  .feed-content {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .feed-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .feed-actions {
    display: flex;
    gap: 15px;
    font-size: 14px;
    color: #cccccc;
  }
  
  .error {
    color: #ff4d4d;
    font-size: 18px;
    text-align: center;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .user-feed-page {
      padding: 30px 10px;
    }
  
    .feed-item {
      padding: 15px;
    }
  
    .feed-header h3 {
      font-size: 1.2rem;
    }
  
    .feed-content {
      font-size: 14px;
    }
  
    .feed-actions {
      font-size: 12px;
    }
  }
  