/* src/components/ConnectionStatus.css */

/* Connection Status Container */
.connection-status {
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background for contrast */
    padding: 8px 12px;
    border-radius: 20px; /* Rounded corners for a polished look */
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 1001; /* Ensure it appears above other elements if overlapping */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff; /* Ensure text is visible against dark background */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    opacity: 0.95; /* Slightly higher opacity for better visibility */
    transition: opacity 0.3s, transform 0.3s; /* Smooth transitions for interactions */
    margin-left: var(--spacing-md); /* Space between ConnectionStatus and previous element */
}

.connection-status:hover {
    opacity: 1;
    transform: translateY(-2px); /* Slight lift on hover */
}

/* Status Icon Styling */
.status-icon {
    font-size: 1.5rem; /* Adjust icon size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Adding Spin Animation for Pending Status */
.spin {
    animation: spin 2s linear infinite;
}

/* Spin Animation Keyframes */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Connected Status */
.connection-status.connected .status-icon {
    color: #00cc00; /* Green */
}

/* Disconnected Status */
.connection-status.disconnected .status-icon {
    color: #cc0000; /* Red */
}

/* Pending/Reconnecting Status */
.connection-status.pending .status-icon {
    color: #ffcc00; /* Yellow */
}

/* Responsive Design */
@media (max-width: 768px) {
    .connection-status {
        margin-left: var(--spacing-sm); /* Reduced space on mobile */
        margin-right: var(--spacing-md); /* Space between ConnectionStatus and Theme Toggle */
    }

    .status-icon {
        font-size: 1.2rem; /* Slightly smaller icons on mobile */
    }
}
