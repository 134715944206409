/* src/components/About.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.about-container {
  max-width: 1200px; /* Increased max-width for larger screens */
  margin: 0 auto;
  padding: 60px 20px;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  background: linear-gradient(135deg, #1a1a1a, #2c2c2c); /* Gradient background for depth */
  box-sizing: border-box;
}

.mission-section,
.values-section,
.cta-section {
  margin-bottom: 60px;
}

.mission-section h1,
.values-section h2,
.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #00ff00; /* Neon green heading */
  text-align: center;
  position: relative;
  font-weight: 600;
}

.mission-section h1::after,
.values-section h2::after,
.cta-section h2::after {
  content: '';
  display: block;
  width: 80px; /* Increased width for better visibility */
  height: 4px; /* Increased height for emphasis */
  background: #00ff00;
  margin: 10px auto 0;
  border-radius: 2px;
  animation: pulse 2s infinite; /* Subtle pulsing animation */
}

@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.mission-section p,
.cta-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #cccccc;
  text-align: center;
  max-width: 900px; /* Increased max-width for better readability */
  margin: 0 auto;
  transition: color 0.3s ease;
}

.mission-section p:hover,
.cta-section p:hover {
  color: #ffffff;
}

.values-section ul {
  list-style-type: none;
  padding: 0;
}

.values-section li {
  font-size: 1.1rem;
  margin-bottom: 25px;
  display: flex;
  align-items: flex-start;
  color: #e0e0e0;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease;
  padding: 10px;
  border-radius: 8px;
}

.values-section li:hover {
  transform: translateX(10px);
  background-color: rgba(0, 255, 0, 0.1); /* Light green background on hover */
}

.values-section .icon {
  font-size: 1.5rem;
  margin-right: 15px;
  color: #00ff00;
  flex-shrink: 0; /* Prevent icon from shrinking */
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.values-section li strong {
  color: #00ff00;
  margin-right: 5px;
}

.cta-section {
  background: linear-gradient(135deg, #2c2c2c, #3d3d3d); /* Slightly lighter gradient for distinction */
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 255, 0, 0.2); /* Soft shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-section:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 25px rgba(0, 255, 0, 0.3);
}

.cta-section h2 {
  margin-bottom: 15px;
  color: #00ff00;
  font-weight: 600;
}

.cta-section p {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #dddddd;
}

.cta-button {
  background-color: #00ff00;
  color: #1a1a1a;
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 255, 0, 0.4);
  display: inline-block;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #00e600;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 255, 0, 0.5);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 15px rgba(0, 255, 0, 0.4);
}

@media (max-width: 1024px) {
  .mission-section h1,
  .values-section h2,
  .cta-section h2 {
    font-size: 2.2rem;
  }

  .mission-section p,
  .cta-section p {
    font-size: 1.1rem;
    max-width: 800px;
  }

  .cta-button {
    padding: 12px 25px;
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .mission-section h1,
  .values-section h2,
  .cta-section h2 {
    font-size: 2rem;
  }

  .mission-section p,
  .cta-section p {
    font-size: 1rem;
    max-width: 700px;
  }

  .values-section li {
    flex-direction: column;
    align-items: flex-start;
  }

  .values-section .icon {
    margin-bottom: 10px;
  }

  .cta-button {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .mission-section h1,
  .values-section h2,
  .cta-section h2 {
    font-size: 1.8rem;
  }

  .mission-section p,
  .cta-section p {
    font-size: 0.95rem;
    max-width: 100%;
  }

  .values-section li {
    font-size: 1rem;
  }

  .cta-section {
    padding: 30px 15px;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
