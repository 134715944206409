/* src/components/Contact.css */

/* Container Styles */
.contact-page {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    min-height: 100vh;
    padding: 40px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Heading Styles */
  .contact-page h2 {
    font-size: 36px;
    color: #00ff00; /* Green color for headings */
    margin-bottom: 20px;
  }
  
  /* Form Styles */
  .contact-page form {
    background-color: #333; /* Dark gray background */
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Label Styles */
  .contact-page label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  /* Input and Textarea Styles */
  .contact-page input,
  .contact-page textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  /* Textarea Specific Styles */
  .contact-page textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  /* Button Styles */
  .contact-page button {
    padding: 12px 20px;
    background-color: #28a745; /* Green background */
    color: #000; /* Black text */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .contact-page button:hover {
    background-color: #218838;
    transform: scale(1.05);
  }
  
  /* Response Message Styles */
  .contact-page p {
    font-size: 16px;
    margin-top: 10px;
    color: #ff4d4d; /* Red color for errors */
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .contact-page h2 {
      font-size: 28px;
    }
  
    .contact-page form {
      padding: 15px;
    }
  
    .contact-page button {
      font-size: 14px;
      padding: 10px 16px;
    }
  
    .contact-page label {
      font-size: 14px;
    }
  
    .contact-page input,
    .contact-page textarea {
      font-size: 14px;
    }
  
    .contact-page p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 400px) {
    .contact-page h2 {
      font-size: 24px;
    }
  
    .contact-page button {
      font-size: 12px;
      padding: 8px 12px;
    }
  
    .contact-page label {
      font-size: 12px;
    }
  
    .contact-page input,
    .contact-page textarea {
      font-size: 12px;
    }
  
    .contact-page p {
      font-size: 12px;
    }
  }
  