/* src/components/Profile.css */

.profile-page {
  padding: 50px 20px;
  background-color: #1a1a1a;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.profile-container {
  background-color: #2a2a2a;
  padding: 40px;
  border-radius: 12px;
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  box-shadow: 0 8px 30px rgba(0, 255, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 35px rgba(0, 255, 0, 0.3);
}

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.avatar-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #00ff00;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.avatar-image:hover {
  transform: scale(1.05);
}

.profile-name {
  color: #00ff00;
  margin: 0;
  font-size: 2.5rem;
  text-align: center;
}

.profile-details {
  width: 100%;
  margin-bottom: 30px;
}

.profile-details p {
  font-size: 18px;
  margin: 10px 0;
  line-height: 1.6;
}

.profile-details p strong {
  color: #00ff00;
}

.view-feed-button {
  background-color: #00ff00;
  color: #1a1a1a;
  border: none;
  padding: 12px 25px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 255, 0, 0.3);
}

.view-feed-button:hover {
  background-color: #00e600;
  transform: translateY(-2px);
}

.view-feed-button:active {
  transform: translateY(0);
}

.error {
  color: #ff4d4d;
  font-size: 18px;
  text-align: center;
}

.loading {
  color: #00ff00;
  font-size: 18px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-container {
    padding: 30px;
  }

  .profile-name {
    font-size: 2rem;
  }

  .profile-details p {
    font-size: 16px;
  }

  .view-feed-button {
    width: 100%;
    text-align: center;
  }
}
