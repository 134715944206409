/* Container for the entire grid view */
.grid-container {
  margin: 1rem auto;
  max-width: 80vw;
  padding: 1rem;
}

/* Leader (central user) card at the top */
.leader-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #444;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: #fdfcfb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.leader-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.leader-placeholder {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #fff;
}

/* Grid layout for connected users */
.users-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

/* Each connected user is rendered as a grid item */
.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 0.5rem;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.grid-item:hover {
  transform: scale(1.05);
}

.grid-item .avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
}

.user-label {
  margin-top: 0.5rem;
  font-weight: bold;
  text-align: center;
}

/* Dropdown menu for user actions */
.dropdown-menu {
  position: fixed;
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 0.5rem;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  margin: 0.25rem 0;
  background: #fafafa;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.25rem;
  cursor: pointer;
}

/* Blinking animation for users flagged as blinking */
@keyframes blink {
  0%, 100% { border-color: #ddd; }
  50% { border-color: red; }
}
.blinking {
  animation: blink 1s infinite;
}

/* Theme overrides */

/* Light theme */
.light-grid {
  /* Default styling already works for a light theme */
}

/* Dark theme */
.dark-grid {
  background-color: #1c1c1c;
  color: #f0f0f0;
}

.dark-grid .leader-card {
  background-color: #2f2f2f;
  border-color: #555;
}

.dark-grid .grid-item {
  background-color: #2f2f2f;
  border-color: #555;
}
