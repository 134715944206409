/* 1. General Reset & Layout */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #f8f9fa;
  color: #212529;
  scroll-behavior: smooth;
}

/* 2. Inbox Container */
.inbox-page {
  max-width: 1000px;
  margin: 0 auto;
  /* Increased top padding to 5rem as an example */
  padding: 5rem 1rem 2rem; 
  min-height: 100vh;
}

/* Loading Container */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

/* 3. No Messages */
.no-messages {
  text-align: center;
  font-size: 1rem;
  color: #6c757d;
  margin-top: 4rem;
}

/* 4. Grouped Messages */
.grouped-message-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Message Group */
.message-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.group-header {
  font-size: 1.25rem;
  font-weight: 600;
  color: #343a40;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 0.5rem;
}

/* Message List & Items */
.message-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.message-item {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  transition: background 0.2s ease;
  cursor: pointer;
}

.message-item:hover {
  background: #f1f3f5;
}

/* States for read/unread */
.message-item.read {
  opacity: 0.8;
}

.message-item.unread {
  border-left: 4px solid #0d6efd; /* Blue accent for unread */
}

.message-item.sent {
  border-left: 4px solid #6c757d;
}

.message-item.received {
  /* In this example, we just let it default */
}

/* Summary */
.message-summary {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.message-from {
  font-size: 0.95rem;
  font-weight: 500;
  color: #212529;
}

.message-content {
  font-size: 0.875rem;
  color: #495057;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
}

.message-date {
  font-size: 0.75rem;
  color: #6c757d;
}

.new-badge {
  margin-top: 0.25rem;
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  background-color: #dc3545;
  color: #fff;
}

.read-status {
  margin-top: 0.25rem;
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #198754; /* Green */
}

.read-status.unread-recipient {
  background-color: #ffc107; /* Yellow if unread by recipient */
}

/* Actions */
.message-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 1rem;
}

.message-actions button {
  border: none;
  border-radius: 0.25rem;
  background: #e9ecef;
  color: #212529;
  font-size: 0.8rem;
  padding: 0.4rem 0.75rem;
  cursor: pointer;
}

.message-actions button:hover {
  background: #dee2e6;
}

.mark-read-button {
}

.delete-button {
  background: #fa5252;
  color: #fff;
}

.delete-button:hover {
  background: #f03e3e;
}

/* 5. Modal Overlay & Content */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 37, 41, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 95%;
  max-width: 600px;
  position: relative;
}

/* Modal Header */
.modal-content h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.5rem;
  color: #212529;
}

/* Modal Text */
.modal-message-content {
  font-size: 0.95rem;
  color: #495057;
  margin-bottom: 1.5rem;
  line-height: 1.4;
  word-wrap: break-word;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

/* Buttons */
.reply-button {
  background: #0d6efd;
  color: #fff;
  padding: 0.4rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.reply-button:hover {
  background: #0b5ed7;
}

.close-modal-button {
  position: static;
  background: #6c757d;
  color: #fff;
  padding: 0.4rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.close-modal-button:hover {
  background: #5c636a;
}

/* Reply Form */
.reply-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.reply-form label {
  font-weight: 500;
  font-size: 0.85rem;
  color: #212529;
}

.reply-form textarea {
  width: 100%;
  min-height: 80px;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.send-reply-button {
  align-self: flex-end;
  background: #0d6efd;
  color: #fff;
  font-size: 0.9rem;
  padding: 0.4rem 0.75rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.send-reply-button:hover {
  background: #0b5ed7;
}

.send-reply-button:disabled {
  background: #adb5bd;
  cursor: not-allowed;
}

/* 6. Feedback Message */
.feedback-message {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: #0d6efd;
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  z-index: 3000;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  font-size: 0.9rem;
}

/* 7. Spinner */
.spinner {
  border: 5px solid #e9ecef;
  border-top: 5px solid #0d6efd;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  animation: spin 1s linear infinite;
}

.spinner.small {
  width: 18px;
  height: 18px;
  border: 3px solid #e9ecef;
  border-top: 3px solid #0d6efd;
}

/* 8. Animations */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
