/* src/components/SearchResults.css */

.search-results-page {
  padding: 20px; /* Reduced padding for mobile */
  background-color: #1a1a1a;
  color: #ffffff;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Reduced spacing */
}

.header h1 {
  color: #00ff00;
  margin-left: 15px; /* Reduced margin */
  font-size: 1.8rem; /* Adjust font size */
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
}

.user-list li {
  background-color: #333333;
  padding: 12px 15px; /* Adjusted padding */
  margin-bottom: 8px; /* Reduced margin */
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s, transform 0.2s;
}

.user-list li:hover {
  background-color: #444444;
  transform: scale(1.02);
}

.user-info {
  cursor: pointer;
  flex-grow: 1; /* Allows user info to take up available space */
  margin-right: 10px; /* Added margin to separate from buttons */
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-group {
  display: flex;
  gap: 8px; /* Reduced space between buttons */
}

.view-button {
  background-color: #007bff; /* Blue background */
  color: #ffffff;
  border: none;
  padding: 8px 12px; /* Adjusted padding */
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap; /* Prevent button text from wrapping */
  transition: background-color 0.3s, transform 0.2s;
}

.view-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.view-button:disabled {
  background-color: #555555;
  cursor: not-allowed;
}

.connect-button {
  background-color: #00ff00;
  color: #1a1a1a;
  border: none;
  padding: 8px 12px; /* Adjusted padding */
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap; /* Prevent button text from wrapping */
  transition: background-color 0.3s, transform 0.2s;
}

.connect-button:hover {
  background-color: #00cc00;
}

.connect-button.connected {
  background-color: #555555;
  cursor: not-allowed;
}

.connect-button.pending {
  background-color: #ffa500; /* Orange color for pending */
  cursor: not-allowed;
}

/* New styles for the Message button */
.message-button {
  background-color: #ff69b4; /* Hot pink background */
  color: #ffffff;
  border: none;
  padding: 8px 12px; /* Adjusted padding */
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap; /* Prevent button text from wrapping */
  transition: background-color 0.3s, transform 0.2s;
}

.message-button:hover {
  background-color: #ff1493; /* Deep pink on hover */
  transform: scale(1.05);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background-color: #1a1a1a;
  padding: 15px; /* Reduced padding */
  border-radius: 4px;
  width: 90%; /* Changed from 500px to 90% for better mobile fit */
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 255, 0, 0.3);
}

.modal h2 {
  color: #00ff00;
  margin-bottom: 10px; /* Reduced margin */
  font-size: 1.5rem; /* Adjusted font size */
}

.modal textarea {
  width: 100%;
  height: 80px; /* Reduced height */
  background-color: #333333;
  color: #ffffff;
  border: none;
  padding: 8px;
  margin-bottom: 10px; /* Reduced margin */
  resize: none;
  border-radius: 4px;
  font-size: 14px;
}

.modal textarea::placeholder {
  color: #aaaaaa;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.modal-buttons button {
  background-color: #00ff00;
  color: #1a1a1a;
  border: none;
  padding: 8px 12px; /* Adjusted padding */
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  margin-left: 8px; /* Reduced margin */
  transition: background-color 0.3s, transform 0.2s;
}

.modal-buttons button:hover {
  background-color: #00cc00;
  transform: scale(1.05);
}

.modal-buttons button:disabled {
  background-color: #555555;
  cursor: not-allowed;
}

.char-count {
  font-size: 12px;
  color: #999;
  text-align: right;
  margin-top: -8px; /* Adjusted margin */
  margin-bottom: 8px; /* Adjusted margin */
}

.char-count.warning {
  color: #ff4d4d; /* Red color when 10 or fewer characters remain */
}

/* Responsive Design */

@media (max-width: 768px) {
  .search-results-page {
    padding: 15px 10px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header h1 {
    margin-left: 0;
    margin-top: 10px;
    font-size: 1.4rem; /* Further reduced font size */
  }

  .user-list {
    max-width: 100%;
  }

  .user-list li {
    flex-direction: column;
    align-items: flex-start;
  }

  .button-group {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 6px; /* Adjust gap if needed */
  }

  .view-button,
  .connect-button,
  .message-button {
    width: 48%; /* Adjust buttons to take up half the width */
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 1.2rem;
  }

  .view-button,
  .connect-button,
  .message-button {
    width: 100%; /* Stack buttons vertically on very small screens */
    margin: 5px 0;
  }

  .modal {
    padding: 10px;
  }

  .modal h2 {
    font-size: 1.3rem;
  }

  .modal textarea {
    height: 60px;
  }

  .modal-buttons button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .char-count {
    font-size: 10px;
  }
}
