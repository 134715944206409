/* src/components/FeedItem.css */

.feed-item {
  background-color: var(--color-surface);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-elevation-1);
  margin-bottom: var(--spacing-xl);
  transition: box-shadow var(--transition-duration), transform var(--transition-duration);
  display: flex;
  flex-direction: column;
  position: relative; /* For burst effect positioning */
}

.feed-item:hover {
  box-shadow: var(--shadow-elevation-3);
  transform: translateY(-4px);
}

.feed-item-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--color-primary);
  transition: transform var(--transition-duration), border-color var(--transition-duration);
}

.avatar:hover {
  transform: scale(1.05);
  border-color: var(--color-secondary);
}

.author-info h4 {
  margin: 0;
  font-size: var(--font-size-lg);
  color: var(--color-text);
}

.author-info p {
  margin: 4px 0 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
}

.feed-item-content p {
  font-size: var(--font-size-md);
  color: var(--color-text);
  margin-bottom: var(--spacing-md);
  line-height: 1.6;
}

.post-image {
  max-width: 100%;
  height: auto;
  border-radius: var(--border-radius-sm);
  object-fit: cover;
  box-shadow: var(--shadow-elevation-1);
  margin-bottom: var(--spacing-md);
  transition: transform var(--transition-duration);
}

.post-image:hover {
  transform: scale(1.02);
  box-shadow: var(--shadow-elevation-2);
}

.feed-item-actions {
  display: flex;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
  align-items: center;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  transition: color var(--transition-duration), transform var(--transition-duration);
  position: relative;
}

.action-button:hover {
  color: var(--color-primary);
  transform: scale(1.1);
}

.action-button:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

.like-button.liked {
  color: #e0245e; /* Pinkish color for liked state */
}

.action-button span {
  display: none; /* Hide count on small screens */
}

.action-button:hover span {
  display: inline;
}

/* Tooltip Styles */
.action-button[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-tooltip);
  color: var(--color-tooltip-text);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  white-space: nowrap;
  font-size: var(--font-size-xs);
  box-shadow: var(--shadow-elevation-1);
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition-duration), visibility var(--transition-duration);
}

.action-button::after {
  content: '';
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-tooltip);
  color: var(--color-tooltip-text);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  white-space: nowrap;
  font-size: var(--font-size-xs);
  box-shadow: var(--shadow-elevation-1);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-duration), visibility var(--transition-duration);
}

.action-button[data-tooltip]::before {
  content: '';
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--color-tooltip) transparent transparent transparent;
}

.action-button[data-tooltip]:hover::before {
  display: block;
}

/* Burst Effect Styles */
.burst-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Allows clicks to pass through */
  display: flex;
  gap: 8px;
}

.burst-heart {
  color: #e0245e;
  font-size: 24px;
  opacity: 0.8;
  animation: burst 1s ease-out forwards;
}

@keyframes burst {
  0% {
      transform: scale(0.5);
      opacity: 1;
  }
  100% {
      transform: scale(2);
      opacity: 0;
  }
}

/* Load More Comments Button */
.load-more-comments {
  background-color: var(--color-button);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-xs) var(--spacing-sm);
  cursor: pointer;
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-sm);
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.load-more-comments:hover {
  background-color: var(--color-button-hover);
  transform: scale(1.02);
}

.load-more-comments:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: 2px;
}

/* Delete Button Styles */
.delete-button {
  color: var(--color-error);
  font-size: var(--font-size-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  transition: color var(--transition-duration), transform var(--transition-duration);
}

.delete-button:hover {
  color: var(--color-error-text);
  transform: scale(1.1);
}

.delete-button:disabled {
  color: var(--color-text-secondary);
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .feed-item {
      padding: var(--spacing-sm);
  }

  .avatar {
      width: 50px;
      height: 50px;
  }

  .author-info h4 {
      font-size: var(--font-size-md);
  }

  .feed-item-content p {
      font-size: var(--font-size-sm);
  }

  .action-button span {
      display: none;
  }

  .action-button:hover span {
      display: none;
  }

  /* Adjust tooltips for mobile */
  .action-button[data-tooltip]:hover::after {
      bottom: 130%;
  }

  .burst-heart {
      font-size: 20px;
  }

  .load-more-comments {
      font-size: var(--font-size-xs);
      padding: var(--spacing-xs) var(--spacing-xs);
  }
}
