/* =========================
    1. Global Box-Sizing
========================= */

/* Apply box-sizing globally */
*, *::before, *::after {
    box-sizing: border-box;
}

/* =========================
    2. CSS Variable Definitions
========================= */

/* Define Top Bar Height and Other Variables */
:root {
    --top-bar-height: 60px; /* Default height for desktop */
    --spacing-xxs: 4px;
    --spacing-xs: 8px;
    --spacing-sm: 12px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;
    --border-radius-sm: 4px;
    --shadow-elevation-1: 0 1px 3px rgba(0, 0, 0, 0.2);
    --shadow-elevation-2: 0 3px 6px rgba(0, 0, 0, 0.3);
    --shadow-elevation-3: 0 10px 20px rgba(0, 0, 0, 0.4);
    --color-grey: #6c757d;
    --color-grey-hover: #5a6268;
    --color-grey-active: #545b62;
    --color-primary: #007bff;
    --color-primary-variant: #0056b3; /* Added for hover state */
    --color-secondary: #03dac6;
    --color-secondary-variant: #018786; /* Added for hover state */
    --color-error: #dc3545;
    --color-text: #ffffff;
    --color-text-secondary: #adb5bd; /* Added for secondary text */
    --color-surface: #343a40;
    --color-border: #ced4da;
    --color-hover: rgba(255, 255, 255, 0.1);
    --color-shadow: rgba(0, 0, 0, 0.5);
    --transition-duration: 0.3s;
    --transition-ease: ease;
    --z-index-modal: 3002; /* Defined for modal layering */
}

/* =========================
    3. Top Bar Styles (Updated for Alignment)
========================= */

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically center all child elements */
    padding: 0 var(--spacing-lg); /* Horizontal padding */
    background-color: var(--color-surface);
    position: fixed; /* Fixed position */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: var(--top-bar-height); /* Use CSS variable */
    z-index: 3000; /* High z-index */
    box-shadow: var(--shadow-elevation-1);
    transition: background-color var(--transition-duration) var(--transition-ease),
                box-shadow var(--transition-duration) var(--transition-ease),
                transform 0.3s ease-in-out;
    overflow: visible; /* Prevent clipping */
}

/* Ensure all direct children of .top-bar use flex and are vertically centered */
.top-bar > * {
    display: flex;
    align-items: center; /* Vertically center */
}

/* Left Section: Logo and Connection Status */
.top-bar-left {
    display: flex;
    align-items: center;
    gap: var(--spacing-md); /* Space between logo and connection status */
}

/* Logo Styles */
.logo {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
}

.logo img {
    width: 50px; /* Increased from 40px to 50px */
    height: 50px; /* Increased from 40px to 50px */
    border-radius: 50%;
    object-fit: cover;
    transition: transform var(--transition-duration);
}

.logo img:hover {
    transform: scale(1.1); /* Slightly larger scale for better effect */
}

/* Connection Status */
.connection-status {
    /* Style as needed */
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
}

/* =========================
    4. Right Section Styles: Theme Toggle, Search, Notifications, Avatar, and Hamburger Menu
========================= */

.top-bar-right {
    display: flex;
    align-items: center;
    gap: var(--spacing-md); /* Space between elements */
}

/* Theme Toggle Button */
.theme-toggle {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--color-text);
    transition: color var(--transition-duration);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; /* Consistent height */
    width: 40px; /* Consistent width */
    border-radius: var(--border-radius-sm);
}

.theme-toggle:hover {
    color: var(--color-primary);
}

/* Search Form Container */
.search-form {
    display: flex;
    align-items: center;
    position: relative;
    /* Removed margin-left to manage spacing via gap */
}

/* Search Input */
.search-input {
    padding: var(--spacing-xs) var(--spacing-md);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-sm);
    outline: none;
    transition: border-color var(--transition-duration), box-shadow var(--transition-duration);
    width: 200px;
    max-width: 300px;
    background-color: var(--color-surface);
    color: var(--color-text);
    height: 40px; /* Increased height for better touch targets */
    box-shadow: var(--shadow-elevation-1);
}

.search-input::placeholder {
    color: var(--color-text-secondary);
}

.search-input:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary);
}

/* Search Button */
.search-button {
    position: absolute;
    right: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--color-text);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; /* Match input height */
    width: 40px; /* Match input height for square button */
    border-radius: 50%;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.search-button:hover {
    color: var(--color-primary);
    background-color: var(--color-hover);
    transform: scale(1.1);
}

/* Notification Icon */
.notification-icon {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the icon content */
    cursor: pointer;
    color: var(--color-text);
    font-size: 1.5rem;
    transition: color var(--transition-duration), transform var(--transition-duration);
    height: 40px; /* Consistent height */
    width: 40px; /* Consistent width */
    border-radius: 50%; /* Circular shape */
    background-color: var(--color-hover); /* Subtle background for visibility */
    position: relative; /* For badge positioning */
}

.notification-icon:hover {
    color: var(--color-primary);
    background-color: var(--color-surface);
    transform: scale(1.05); /* Slight enlargement on hover */
}

.notification-icon .notification-badge {
    position: absolute;
    top: 5px; /* Adjusted for better positioning */
    right: 5px;
    background-color: var(--color-error);
    color: var(--color-text);
    border-radius: 50%;
    padding: var(--spacing-xxs);
    font-size: var(--font-size-xs);
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* User Avatar */
.user-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible; /* Prevent clipping */
}

.avatar-image {
    display: block; /* Prevent inline spacing issues */
    width: 40px; /* Default width */
    height: 40px; /* Default height */
    border-radius: 50%;
    object-fit: cover;
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
    margin: 0; /* Remove any default margins */
    padding: 0; /* Remove any default padding */
}

.avatar-image:hover {
    transform: scale(1.1);
    box-shadow: var(--shadow-elevation-2);
}

/* Hamburger Menu Button */
.hamburger-menu {
    display: flex; /* Flex for alignment */
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--color-text);
    height: 40px; /* Consistent height */
    width: 40px; /* Consistent width */
    border-radius: var(--border-radius-sm);
    transition: color var(--transition-duration), transform var(--transition-duration);
}

.hamburger-menu:hover {
    color: var(--color-primary);
    transform: rotate(90deg);
}

/* =========================
    5. Navigation Buttons (Dropdown Menu)
========================= */

/* Hide navigation buttons by default on all screens */
.top-buttons {
    display: none; /* Hidden by default */
}

/* When active, display navigation buttons */
.top-buttons.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: var(--top-bar-height);
    right: 10px;
    width: 220px;
    background-color: var(--color-surface);
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-elevation-2);
    padding: var(--spacing-xs);
    gap: var(--spacing-sm);
    z-index: 3001; /* Higher than .top-bar */
}

/* Mobile Button Styles */
.top-buttons .button.gray-button,
.top-buttons .button.logout-button {
    width: 100%; /* Full width for buttons in menu */
    max-width: none; /* Remove max width */
    padding: var(--spacing-xs);
    font-size: var(--font-size-xs); /* Smaller font size */
    height: 35px; /* Slightly smaller height */
    margin: 5px 0; /* Space between buttons */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    transition: background-color var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);
}

/* Logout Button Specific Styles */
.top-buttons .button.logout-button {
    background-color: var(--color-error);
    color: var(--color-text);
}

.top-buttons .button.logout-button:hover {
    background-color: #B00020; /* Darker red for hover */
    box-shadow: var(--shadow-elevation-2);
    transform: translateY(-2px); /* Consistent with gray buttons */
}

/* =========================
    6. Notifications Popup Styles
========================= */

/* Notifications Popup */
.notifications-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn var(--transition-duration) forwards;
}

/* Popup Overlay */
.notifications-popup .popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Popup Content */
.notifications-popup .popup-content {
    position: relative;
    background-color: var(--color-surface);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-sm);
    width: 90%;
    max-width: 500px;
    max-height: 80%;
    overflow-y: auto;
    animation: slideDown var(--transition-duration) forwards;
    box-shadow: var(--shadow-elevation-3); /* Enhanced shadow for depth */
}

/* Close Popup Button */
.notifications-popup .close-popup {
    position: absolute;
    top: var(--spacing-sm);
    right: var(--spacing-sm);
    background: transparent;
    border: none;
    font-size: var(--font-size-lg);
    color: var(--color-error);
    cursor: pointer;
    transition: color var(--transition-duration), transform var(--transition-duration);
}

.notifications-popup .close-popup:hover {
    color: #B00020; /* Darker red for hover */
    transform: rotate(90deg);
}

/* Notifications List */
.notifications-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.notifications-list li {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm) 0;
    border-bottom: 1px solid var(--color-border);
}

.notifications-list li.unread {
    background-color: var(--color-hover);
}

.notifications-list li:last-child {
    border-bottom: none;
}

.notification-timestamp {
    font-size: var(--font-size-xs);
    color: var(--color-text-secondary);
}

/* Mark as Read Button */
.notifications-list li button {
    align-self: flex-end;
    padding: var(--spacing-xxs) var(--spacing-xs);
    background-color: var(--color-primary);
    border: none;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    color: var(--color-text);
    font-size: var(--font-size-xs);
    transition: background-color var(--transition-duration);
}

.notifications-list li button:hover {
    background-color: var(--color-primary-variant);
}

/* Mark All as Read Button */
.notifications-popup .popup-content > button.mark-all-read-button {
    margin-top: var(--spacing-md);
    padding: var(--spacing-xs) var(--spacing-md);
    background-color: var(--color-secondary);
    border: none;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    color: var(--color-text);
    font-size: var(--font-size-sm);
    transition: background-color var(--transition-duration);
}

.notifications-popup .popup-content > button.mark-all-read-button:hover {
    background-color: var(--color-secondary-variant);
}

/* =========================
    7. Animations
========================= */

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideDown {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* =========================
    8. Media Queries for Mobile
========================= */

@media (max-width: 768px) {
    /* Adjust Top Bar Padding for Mobile */
    .top-bar {
        padding: 0 var(--spacing-md);
        height: var(--top-bar-height); /* Use CSS variable */
    }

    /* Adjust `.top-buttons` positioning for mobile */
    .top-buttons.active {
        width: 220px;
    }

    /* Hide the search form in the top-bar-right when menu is active */
    .search-form.mobile-hidden {
        display: none;
    }

    /* Hide theme toggle when menu is active */
    .theme-toggle.mobile-hidden {
        display: none;
    }

    /* Adjust notification icon for smaller screens */
    .notification-icon {
        font-size: 1.2rem;
        height: 35px;
        width: 35px;
    }

    /* Adjust Search Input Width */
    .search-input {
        width: 150px;
    }

    /* Adjust Buttons in `.top-buttons` for Mobile */
    .top-buttons .button.gray-button,
    .top-buttons .button.logout-button {
        height: 35px; /* Slightly smaller height */
        font-size: var(--font-size-xs); /* Smaller font size */
    }

    /* Adjust Theme Toggle and Hamburger Menu Sizes */
    .theme-toggle,
    .hamburger-menu {
        height: 35px;
        width: 35px;
        font-size: 1.2rem;
    }

    /* Stack elements horizontally without reversing */
    .top-bar-right {
        flex-direction: row; /* Ensure row direction */
        gap: var(--spacing-sm); /* Reduced gap for smaller screens */
    }

    /* Push hamburger menu to the far right */
    .hamburger-menu {
        margin-left: auto;
    }

    /* Add spacing between search bar and notification icon */
    .top-bar-right .search-form + .notification-icon {
        margin-left: var(--spacing-lg); /* Adjust the value as needed */
    }

    /* Optional: Adjust `.top-buttons.active` positioning if needed */
    .top-buttons.active {
        top: var(--top-bar-height);
        right: 10px;
    }

    /* Adjust User Avatar Size for Mobile */
    .avatar-image {
        width: 35px; /* Smaller width on mobile */
        height: 35px; /* Smaller height on mobile */
    }

    /* **Integrated Changes to Prevent Logo Clipping** */

    /* Adjust Logo Size for Mobile Devices */
    .logo img {
        width: 35px; /* Reduced from 50px */
        height: 35px; /* Reduced from 50px */
    }

    /* Optional: Adjust top-bar height if necessary */
    /* Uncomment the following lines if further adjustment is needed */
    /*
    .top-bar {
        height: 65px; /* Increase from 60px to 65px */
    }

/* =========================
    9. Accessibility Enhancements
========================= */

/* High Contrast Mode */
@media (prefers-contrast: high) {
    :root {
        --color-background: #000000;
        --color-surface: #1E1E1E;
        --color-text: #FFFFFF;
        --color-text-secondary: #CCCCCC;
        --color-border: #FFFFFF;
        --color-hover: rgba(255, 255, 255, 0.2);
    }

    a,
    button {
        outline: 2px solid var(--color-primary);
    }
}

/* Focus Styles */
a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: 3px solid var(--color-primary);
    outline-offset: 2px;
}

/* Skip to Content Link */
.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: var(--color-primary);
    color: var(--color-text);
    padding: var(--spacing-xxs) var(--spacing-sm);
    z-index: 1000;
    transition: top var(--transition-duration) var(--transition-ease);
}

.skip-link:focus {
    top: var(--spacing-md);
}

/* =========================
    10. Tooltip Styles
========================= */

/* Tooltip Container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip Text */
.tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 125%; /* Position above the element */
    left: 50%;
    transform: translateX(-50%) translateY(-4px);
    background-color: var(--color-text);
    color: var(--color-background);
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    white-space: nowrap;
    font-size: var(--font-size-xs);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-duration), transform var(--transition-duration);
    pointer-events: none;
    z-index: 1001;
}

.tooltip:hover::after,
.tooltip:focus::after {
    opacity: 1;
    visibility: visible;
}

/* =========================
    11. End of CSS
========================= */
