/* =========================
   1. Reset and Basic Styles
========================= */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
.acceptedConnectionsPage {
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  scroll-behavior: smooth;
}

/* =========================
   2. CSS Variables for Theming
========================= */
:root {
  /* Color Palette */
  --color-background: #2B2B2B; /* Dark Grey */
  --color-surface: #3C3C3C;    /* Solid Grey for surface */
  --color-primary: #C0C0C0;    /* Silver */
  --color-primary-variant: #A8A8A8; /* Darker Silver */
  --color-secondary: #D3D3D3; /* Light Grey */
  --color-error: #CF6679;     /* For error messages */
  --color-text: #E0E0E0;      /* Light Text */
  --color-text-secondary: #B3B3B3;
  --color-border: #555555;    /* Grey Border */
  --color-hover: rgba(255, 255, 255, 0.1);
  --color-read: #00cc00;          /* Green for read messages */
  --color-unread: #ff00ff;        /* Magenta for unread sent messages */
  --color-unread-recipient: #cc0000; /* Red for unread by recipient */

  /* Spacing */
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-sm: 12px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;

  /* Font Sizes */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;

  /* Shadows */
  --shadow-elevation-1: 0px 1px 3px rgba(0, 0, 0, 0.2);
  --shadow-elevation-2: 0px 4px 6px rgba(0, 0, 0, 0.3);
  --shadow-elevation-3: 0px 10px 20px rgba(0, 0, 0, 0.4);

  /* Transition */
  --transition-duration: 0.3s;
  --transition-ease: ease-in-out;

  /* Z-Index */
  --z-index-modal: 1000;

  /* You can define a custom variable for top-bar if needed */
  --top-bar-height: 60px; /* Example fallback if used */
}

/* =========================
   3. Accepted Connections Page Styles
========================= */

/* The page container */
.acceptedConnectionsPage {
  background: var(--color-surface);
  color: var(--color-text);
  padding: var(--spacing-lg);
  padding-top: calc(var(--top-bar-height) + var(--spacing-lg));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn var(--transition-duration) var(--transition-ease);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-elevation-2);
  transition: background var(--transition-duration), box-shadow var(--transition-duration);
}

.acceptedConnectionsPage:hover {
  background: #4A4A4A; /* Darker grey on hover */
  box-shadow: var(--shadow-elevation-3);
}

/* Header */
.acceptedConnectionsHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: var(--spacing-xl);
}

.acceptedConnectionsHeader h2 {
  color: var(--color-secondary);
  font-size: var(--font-size-xxl);
  position: relative;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-md);
}

.acceptedConnectionsHeader h2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -var(--spacing-xs);
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--color-primary);
  border-radius: var(--border-radius-sm);
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: var(--font-size-lg);
  color: var(--color-text-secondary);
}

/* Spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
  margin-right: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Connections List */
.connectionsList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.connectionItem {
  background: var(--color-surface);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition:
    background var(--transition-duration),
    transform var(--transition-duration),
    box-shadow var(--transition-duration);
  cursor: pointer;
  box-shadow: var(--shadow-elevation-1);
}

.connectionItem:hover {
  background: rgba(192, 192, 192, 0.1);
  transform: translateY(-2px);
  box-shadow: var(--shadow-elevation-2);
}

/* User Info */
.userInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

/* Connection Avatar */
.connectionAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--color-border);
  transition:
    border-color var(--transition-duration),
    transform var(--transition-duration);
}

.connectionAvatar:hover {
  border-color: var(--color-primary);
  transform: scale(1.05);
}

.connectionDetails h3 {
  font-size: var(--font-size-lg);
  color: var(--color-text);
  margin-bottom: 4px;
}

.connectionDetails p {
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
}

/* Send Message Button */
.sendMessageButton {
  padding: var(--spacing-xxs) var(--spacing-sm);
  background-color: #09f;
  color: #fff;
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background var(--transition-duration), transform var(--transition-duration);
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
}

.sendMessageButton:hover {
  background-color: #007acc;
  transform: scale(1.05);
}

/* More Options (Three-Dot) Button */
.moreOptionsWrapper {
  position: relative;
  margin-left: var(--spacing-sm);
}

.moreOptionsButton {
  background: transparent;
  border: none;
  color: var(--color-text);
  font-size: var(--font-size-xl);
  padding: var(--spacing-xxs) var(--spacing-xs);
  cursor: pointer;
  line-height: 1;
}

.moreOptionsButton:hover {
  color: var(--color-primary);
}

/* Dropdown Menu for Options */
.optionsMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background: var(--color-surface);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-elevation-2);
  padding: var(--spacing-xxs) 0;
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-xxs);
  z-index: var(--z-index-modal);
  animation: slideDown 0.2s ease;
}

.optionItem {
  background: transparent;
  border: none;
  color: var(--color-text);
  padding: var(--spacing-xs) var(--spacing-sm);
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: var(--font-size-sm);
}

.optionItem:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Error Message */
.errorMessage {
  color: var(--color-error);
  font-weight: bold;
  font-size: var(--font-size-md);
  text-align: center;
  margin-top: var(--spacing-lg);
}

/* =========================
   4. Animations
========================= */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(192, 192, 192, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0);
  }
}

/* =========================
   5. Responsive Styles
========================= */
@media (max-width: 768px) {
  .acceptedConnectionsPage {
    padding: var(--spacing-md);
    padding-top: calc(var(--top-bar-height) + var(--spacing-md));
  }

  .acceptedConnectionsHeader h2 {
    font-size: var(--font-size-xl);
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-md);
  }

  .connectionItem {
    padding: var(--spacing-sm);
  }

  .connectionAvatar {
    width: 50px;
    height: 50px;
  }

  .connectionDetails h3 {
    font-size: var(--font-size-md);
  }

  .connectionDetails p {
    font-size: var(--font-size-xs);
  }

  .sendMessageButton {
    font-size: var(--font-size-xs);
    padding: var(--spacing-xxs) var(--spacing-xs);
  }
}

@media (max-width: 600px) {
  .acceptedConnectionsPage {
    padding: var(--spacing-sm);
    padding-top: calc(var(--top-bar-height) + var(--spacing-sm));
  }

  .acceptedConnectionsHeader h2 {
    font-size: var(--font-size-lg);
    padding-top: var(--spacing-xxs);
    padding-bottom: var(--spacing-md);
  }

  .connectionAvatar {
    width: 40px;
    height: 40px;
  }

  .userInfo {
    gap: var(--spacing-xs);
  }

  .connectionDetails h3 {
    font-size: var(--font-size-md);
  }

  .connectionDetails p {
    font-size: var(--font-size-xs);
  }

  .sendMessageButton {
    font-size: var(--font-size-xs);
    padding: var(--spacing-xxs) var(--spacing-xs);
  }
}

@media (max-width: 400px) {
  .acceptedConnectionsPage {
    padding: var(--spacing-xs);
    padding-top: calc(var(--top-bar-height) + var(--spacing-xs));
  }

  .acceptedConnectionsHeader h2 {
    font-size: var(--font-size-md);
    padding-top: var(--spacing-xxs);
    padding-bottom: var(--spacing-md);
  }

  .connectionItem {
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-sm);
  }

  .userInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xs);
  }

  .connectionsList {
    margin-top: var(--spacing-md);
  }

  .sendMessageButton {
    width: 100%;
    justify-content: center;
    margin-top: var(--spacing-sm);
  }

  .connectionAvatar {
    width: 35px;
    height: 35px;
  }
}
