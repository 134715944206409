/* =========================
    1. Reset and Basic Styles
========================= */

/* Reset and basic styles */
*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body,
html,
.dashboard-page {
    height: 100%;
    font-family: 'Inter', 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--color-background);
    color: var(--color-text);
    scroll-behavior: smooth;
    transition: background-color var(--transition-duration) var(--transition-ease),
                color var(--transition-duration) var(--transition-ease);
}

/* =========================
    2. CSS Variables for Theming (Including Grey Variables)
========================= */

:root {
    /* Color Palette - Dark Theme (Default) */
    --color-background: #121212;
    --color-surface: #1E1E1E;
    --color-primary: #BB86FC;
    --color-primary-variant: #3700B3;
    --color-secondary: #03DAC6;
    --color-secondary-variant: #018786;
    --color-error: #CF6679;
    --color-text: #FFFFFF;
    --color-text-secondary: #B3B3B3;
    --color-border: #2C2C2C;
    --color-hover: rgba(255, 255, 255, 0.1);
    --color-shadow: rgba(0, 0, 0, 0.5);

    /* Grey Palette */
    --color-grey: #6c757d; /* Medium Grey */
    --color-grey-hover: #5a6268; /* Darker Grey for Hover */
    --color-grey-active: #545b62; /* Even Darker Grey for Active State */

    /* Spacing */
    --spacing-xxs: 4px;
    --spacing-xs: 8px;
    --spacing-sm: 12px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 48px;

    /* Font Sizes */
    --font-size-xs: 0.75rem;
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.25rem;
    --font-size-xl: 1.5rem;
    --font-size-xxl: 2rem;

    /* Border Radius */
    --border-radius-sm: 4px;
    --border-radius-md: 8px;
    --border-radius-lg: 16px;

    /* Shadows */
    --shadow-elevation-1: 0px 1px 3px rgba(0,0,0,0.2);
    --shadow-elevation-2: 0px 4px 6px rgba(0,0,0,0.3);
    --shadow-elevation-3: 0px 10px 20px rgba(0,0,0,0.4);

    /* Transition */
    --transition-duration: 0.3s;
    --transition-ease: ease-in-out;

    /* Z-Index */
    --z-index-top-bar: 1000; /* New variable for Top Bar */
    --z-index-modal: 1000;

    /* Light Theme Colors */
    --light-color-background: #FFFFFF; /* Pure white for a clean look */
    --light-color-surface: #F0F4F8; /* Light blue-gray for surfaces */
    --light-color-primary: #6200EA; /* Vivid purple for primary actions */
    --light-color-primary-variant: #3700B3; /* Darker purple for variants */
    --light-color-secondary: #03DAC6; /* Teal for secondary actions */
    --light-color-secondary-variant: #018786; /* Darker teal for variants */
    --light-color-error: #B00020; /* Standard error color */
    --light-color-text: #000000; /* Black text for high readability */
    --light-color-text-secondary: #333333; /* Dark gray for secondary text */
    --light-color-border: #E0E0E0; /* Light gray for borders */
    --light-color-hover: rgba(98, 0, 234, 0.1); /* Semi-transparent primary for hover effects */
}

/* =========================
    3. Typography Enhancements
========================= */

/* Base Typography */
body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: var(--spacing-sm);
}

p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin-bottom: var(--spacing-md);
}

/* =========================
    4. Search Form Styles
========================= */

/* Search Form Container */
.search-form {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: var(--spacing-md);
}

/* Search Input */
.search-input {
    padding: var(--spacing-xs) var(--spacing-md);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-sm);
    outline: none;
    transition: border-color var(--transition-duration), box-shadow var(--transition-duration);
    width: 200px;
    max-width: 300px;
    background-color: var(--color-surface);
    color: var(--color-text);
    height: 40px; /* Increased height for better touch targets */
    box-shadow: var(--shadow-elevation-1);
}

.search-input::placeholder {
    color: var(--color-text-secondary);
}

.search-input:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary);
}

/* Search Button */
.search-button {
    position: absolute;
    right: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--color-text);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; /* Match input height */
    width: 40px; /* Match input height for square button */
    border-radius: 50%;
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.search-button:hover {
    color: var(--color-primary);
    background-color: var(--color-hover);
    transform: scale(1.1);
}

/* Mobile Search Form (Hidden by default) */
.search-form.mobile-search {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-xs);
}

/* =========================
    5. Dashboard Container Adjustments
========================= */

.dashboard-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--top-bar-height));
    padding-top: var(--top-bar-height); /* Pushes content below Top Bar */
    background-color: var(--color-background);
}

/* =========================
    6. Main Content Area Styles
========================= */

.main-content {
    flex-grow: 1;
    padding: var(--spacing-lg);
    background-color: var(--color-background);
    overflow-y: auto;
    transition: margin-left var(--transition-duration);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
}

.main-content h2 {
    color: var(--color-primary);
    margin-bottom: var(--spacing-md);
    font-size: var(--font-size-xl);
    position: relative;
}

.main-content h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -var(--spacing-xxs);
    width: 60px; /* Increased width for better visibility */
    height: 4px; /* Increased height for emphasis */
    background-color: var(--color-primary);
    border-radius: var(--border-radius-sm);
}

.main-content p {
    font-size: var(--font-size-md);
    color: var(--color-text-secondary);
    line-height: 1.8; /* Increased line height for better readability */
}

/* =========================
    7. Feeds Container Styles
========================= */

.feeds-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-lg);
}

/* Feed Sections */
.feed-section,
.live-feed-section {
    background-color: var(--color-surface);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-elevation-1);
    transition: box-shadow var(--transition-duration), transform var(--transition-duration);
    margin-top: var(--spacing-lg); /* Pushes sections down */
    display: flex;
    flex-direction: column;
}

.feed-section:hover,
.live-feed-section:hover {
    box-shadow: var(--shadow-elevation-2);
    transform: translateY(-4px); /* Increased translation for a noticeable effect */
}

.feed-section h2,
.live-feed-section h2 {
    color: var(--color-primary);
    margin-bottom: var(--spacing-md);
    font-size: var(--font-size-lg);
    position: relative;
}

.feed-section h2::after,
.live-feed-section h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -var(--spacing-xxs);
    width: 60px; /* Increased width for better visibility */
    height: 4px; /* Increased height for emphasis */
    background-color: var(--color-primary);
    border-radius: var(--border-radius-sm);
}

/* =========================
    8. Feeds Items and Actions
========================= */

/* Feed Items List */
.feed-items {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
}

.feed-item,
.live-feed-item {
    background-color: var(--color-surface);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-border);
    box-shadow: var(--shadow-elevation-1);
    transition: box-shadow var(--transition-duration), transform var(--transition-duration);
    display: flex;
    flex-direction: column;
}

.feed-item:hover,
.live-feed-item:hover {
    box-shadow: var(--shadow-elevation-2);
    transform: translateY(-4px); /* Increased translation for a noticeable effect */
}

/* Feed Item Header */
.feed-item-header,
.live-feed-item-header {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-md);
}

/* Avatar Styles */
.feed-item-avatar,
.live-feed-item-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--color-primary);
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
    box-shadow: var(--shadow-elevation-1);
}

.feed-item-avatar:hover,
.live-feed-item-avatar:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-elevation-2);
}

/* Feed Item Author Info */
.feed-item-author-info,
.live-feed-item-author-info {
    display: flex;
    flex-direction: column;
}

.feed-item-author-info h3,
.live-feed-item-author-info h3 {
    margin: 0;
    font-size: var(--font-size-md);
    color: var(--color-text);
}

.feed-item-author-info p,
.live-feed-item-author-info p {
    margin: 2px 0;
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
}

/* Feed Item Content */
.feed-item-content,
.live-feed-item-content {
    margin-bottom: var(--spacing-md);
}

.feed-item-content p,
.live-feed-item-content p {
    font-size: var(--font-size-md);
    color: var(--color-text);
    line-height: 1.8; /* Increased line height for better readability */
}

.feed-item-content img,
.live-feed-item-content img,
.feed-item-image,
.live-feed-item-image {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-elevation-1);
    object-fit: cover;
    margin-top: var(--spacing-sm);
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.feed-item-content img:hover,
.live-feed-item-content img:hover,
.feed-item-image:hover,
.live-feed-item-image:hover {
    transform: scale(1.02);
    box-shadow: var(--shadow-elevation-2);
}

/* Feed Item Actions */
.feed-item-actions,
.live-feed-item-actions {
    display: flex;
    gap: var(--spacing-sm);
    margin-top: auto; /* Pushes actions to the bottom */
}

/* Action Buttons */
.action-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-sm);
    color: var(--color-text-secondary);
    transition: color var(--transition-duration), transform var(--transition-duration);
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-xs);
    white-space: nowrap; /* Prevent text wrapping */
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    transition: background-color var(--transition-duration), color var(--transition-duration), transform var(--transition-duration);
}

.action-button:hover {
    color: var(--color-primary);
    background-color: var(--color-hover);
    transform: scale(1.05);
}

.action-button:focus {
    outline: 3px solid var(--color-primary);
    outline-offset: 2px;
}

/* Like Button (Specific) */
.like-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-sm);
    color: gray; /* Default state */
    transition: color var(--transition-duration), transform var(--transition-duration);
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-xs);
    white-space: nowrap; /* Prevent text wrapping */
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    transition: background-color var(--transition-duration), color var(--transition-duration), transform var(--transition-duration);
}

.like-button.liked {
    color: red; /* Liked state */
}

.like-button:hover {
    opacity: 0.8;
    transform: scale(1.05);
}

.like-button:focus {
    outline: 3px solid var(--color-primary);
    outline-offset: 2px;
}

/* =========================
    9. Notification System Styles
========================= */

/* Notification Icon */
.notification-icon {
    position: fixed; /* Keep fixed to stay in view */
    top: var(--spacing-md);
    right: var(--spacing-md);
    z-index: var(--z-index-top-bar);
    cursor: pointer;
    color: var(--color-text);
    font-size: 1.8rem; /* Increased size for better visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color var(--transition-duration), transform var(--transition-duration);
    height: 45px; /* Increased size for better touch targets */
    width: 45px; /* Increased size for better touch targets */
    border-radius: 50%;
    background-color: var(--color-hover);
}

.notification-icon:hover {
    color: var(--color-primary);
    background-color: var(--color-surface);
    transform: scale(1.05);
}

/* Icon within Notification Icon */
.notification-icon .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Notification Badge */
.notification-badge {
    position: absolute;
    top: -var(--spacing-xxs);
    right: -var(--spacing-xxs);
    background-color: var(--color-error);
    color: var(--color-text);
    border-radius: 50%;
    padding: var(--spacing-xxs);
    font-size: var(--font-size-xs);
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Notifications Popup */
.notifications-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn var(--transition-duration) forwards;
}

/* Popup Overlay */
.notifications-popup .popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Popup Content */
.notifications-popup .popup-content {
    position: relative;
    background-color: var(--color-surface);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    width: 90%;
    max-width: 500px;
    max-height: 80%;
    overflow-y: auto;
    animation: slideDown var(--transition-duration) forwards;
    color: var(--color-text);
    box-shadow: var(--shadow-elevation-3);
}

/* Close Popup Button */
.notifications-popup .close-popup {
    position: absolute;
    top: var(--spacing-sm);
    right: var(--spacing-sm);
    background: transparent;
    border: none;
    font-size: var(--font-size-lg);
    color: var(--color-error);
    cursor: pointer;
    transition: color var(--transition-duration), transform var(--transition-duration);
}

.notifications-popup .close-popup:hover {
    color: #B00020; /* Darker red for hover */
    transform: rotate(90deg);
}

/* Notifications List */
.notifications-list {
    list-style: none;
    padding: 0;
    margin: var(--spacing-md) 0;
}

.notifications-list li {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm) 0;
    border-bottom: 1px solid var(--color-border);
    transition: background-color var(--transition-duration);
}

.notifications-list li:last-child {
    border-bottom: none;
}

.notifications-list li.unread {
    background-color: var(--color-hover);
}

/* Notification Timestamp */
.notification-timestamp {
    font-size: var(--font-size-xs);
    color: var(--color-text-secondary);
}

/* Mark as Read Button */
.notifications-list li button {
    align-self: flex-end;
    padding: var(--spacing-xxs) var(--spacing-xs);
    background-color: var(--color-primary);
    border: none;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    color: var(--color-text);
    font-size: var(--font-size-xs);
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.notifications-list li button:hover {
    background-color: var(--color-primary-variant);
    transform: scale(1.05);
}

/* Mark All as Read Button */
.notifications-popup .popup-content > button.mark-all-read-button {
    margin-top: var(--spacing-md);
    padding: var(--spacing-xs) var(--spacing-md);
    background-color: var(--color-secondary);
    border: none;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    color: var(--color-text);
    font-size: var(--font-size-sm);
    transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.notifications-popup .popup-content > button.mark-all-read-button:hover {
    background-color: var(--color-secondary-variant);
    transform: scale(1.05);
}

/* =========================
    10. Activities Popup Styles
========================= */

/* Activities Popup */
.activities-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn var(--transition-duration) forwards;
}

/* Popup Overlay */
.activities-popup .popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Popup Content */
.activities-popup .popup-content {
    position: relative;
    background-color: var(--color-surface);
    padding: var(--spacing-lg);
    border-radius: var(--border-radius-md);
    width: 90%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
    animation: slideDown var(--transition-duration) forwards;
    color: var(--color-text);
    box-shadow: var(--shadow-elevation-3);
}

/* Close Popup Button */
.activities-popup .close-popup {
    position: absolute;
    top: var(--spacing-sm);
    right: var(--spacing-sm);
    background: transparent;
    border: none;
    font-size: var(--font-size-lg);
    color: var(--color-error);
    cursor: pointer;
    transition: color var(--transition-duration), transform var(--transition-duration);
}

.activities-popup .close-popup:hover {
    color: #B00020; /* Darker red for hover */
    transform: rotate(90deg);
}

/* Activities List */
.activities-list {
    list-style: none;
    padding: 0;
    margin: var(--spacing-md) 0;
}

.activities-list li {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    padding: var(--spacing-xs) 0;
    border-bottom: 1px solid var(--color-border);
    transition: background-color var(--transition-duration);
}

.activities-list li:last-child {
    border-bottom: none;
}

.activities-list li:hover {
    background-color: var(--color-hover);
}

/* Activity Icon */
.activity-icon {
    font-size: 1.5rem; /* Increased size for better visibility */
    color: var(--color-primary);
}

/* Activity Text */
.activities-list li span {
    flex: 1;
    color: var(--color-text);
    font-size: var(--font-size-sm);
    line-height: 1.6;
}

/* Popup Controls */
.popup-controls {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-md);
}

.popup-controls input[type="text"] {
    flex: 1;
    padding: var(--spacing-xs) var(--spacing-md);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-sm);
    outline: none;
    transition: border-color var(--transition-duration), box-shadow var(--transition-duration);
    background-color: var(--color-surface);
    color: var(--color-text);
    height: 35px;
}

.popup-controls input[type="text"]::placeholder {
    color: var(--color-text-secondary);
}

.popup-controls input[type="text"]:focus {
    border-color: var(--color-primary);
    box-shadow: 0 0 5px var(--color-primary);
}

.popup-controls button {
    padding: var(--spacing-xs) var(--spacing-sm);
    background-color: var(--color-secondary);
    border: none;
    border-radius: var(--border-radius-sm);
    cursor: pointer;
    color: var(--color-text);
    font-size: var(--font-size-sm);
    transition: background-color var(--transition-duration), transform var(--transition-duration);
    height: 35px;
}

.popup-controls button:hover {
    background-color: var(--color-secondary-variant);
    transform: scale(1.05);
}

/* =========================
    11. Comment Section Styles
========================= */

/* Comment Section */
.comment-section {
    margin-top: var(--spacing-sm);
    /* Add other styles as necessary */
}

/* Comment Container */
.comment {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
    padding: var(--spacing-xs) 0;
}

.comment p {
    margin: 0;
    color: var(--color-text-secondary);
    font-size: var(--font-size-sm);
    line-height: 1.6;
}

.comment .comment-timestamp {
    font-size: var(--font-size-xs);
    color: var(--color-text-secondary);
}

/* =========================
    12. Tooltip Styles
========================= */

/* Tooltip Container */
.tooltip {
    position: relative;
    display: inline-block;
}

/* Tooltip Text */
.tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 125%; /* Position above the element */
    left: 50%;
    transform: translateX(-50%) translateY(-4px);
    background-color: var(--color-text);
    color: var(--color-background);
    padding: var(--spacing-xxs) var(--spacing-xs);
    border-radius: var(--border-radius-sm);
    white-space: nowrap;
    font-size: var(--font-size-xs);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-duration), transform var(--transition-duration);
    pointer-events: none;
    z-index: 1001;
}

.tooltip:hover::after,
.tooltip:focus::after {
    opacity: 1;
    visibility: visible;
}

/* =========================
    13. Animations
========================= */

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideDown {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* =========================
    14. Responsive Design Adjustments
========================= */

/* Adjustments for Medium Screens */
@media (max-width: 1024px) {
    .feeds-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

/* Adjustments for Small Screens */
@media (max-width: 768px) {
    /* Adjust main-content padding */
    .main-content {
        padding: var(--spacing-md);
    }

    /* Adjust Feeds Container */
    .feeds-container {
        grid-template-columns: 1fr;
    }

    .feed-section,
    .live-feed-section {
        min-width: 100%;
    }

    .feed-item-header,
    .live-feed-item-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .feed-item-avatar,
    .live-feed-item-avatar {
        margin-right: 0;
        margin-bottom: var(--spacing-sm);
    }

    /* Modal and Popup Content */
    .modal-content,
    .popup-content {
        width: 95%;
        padding: var(--spacing-md);
    }

    /* Feeds Tabs Display on Mobile */
    .feeds-tabs {
        display: flex;
    }

    /* Hide desktop search and theme toggle when mobile menu is active */
    .search-form.mobile-hidden,
    .theme-toggle.mobile-hidden {
        display: none;
    }

    /* Adjust notification icon for smaller screens */
    .notification-icon {
        top: var(--spacing-sm);
        right: var(--spacing-md);
        font-size: 1.2rem;
        height: 35px;
        width: 35px;
    }

    /* Adjust Search Input Width */
    .search-input {
        width: 150px;
    }

    /* Adjust Buttons in `.top-buttons` for Mobile */
    .top-buttons .button.gray-button,
    .top-buttons .button.logout-button {
        height: 35px; /* Slightly smaller height */
        font-size: var(--font-size-xs); /* Smaller font size */
    }

    /* Adjust Theme Toggle and Hamburger Menu Sizes */
    .theme-toggle,
    .hamburger-menu {
        height: 35px;
        width: 35px;
        font-size: 1.2rem;
    }
}

/* =========================
    15. Accessibility Enhancements
========================= */

/* High Contrast Mode */
@media (prefers-contrast: high) {
    :root {
        --color-background: #000000;
        --color-surface: #1E1E1E;
        --color-text: #FFFFFF;
        --color-text-secondary: #CCCCCC;
        --color-border: #FFFFFF;
        --color-hover: rgba(255, 255, 255, 0.2);
    }

    a,
    button {
        outline: 2px solid var(--color-primary);
    }
}

/* Focus Styles */
a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: 3px solid var(--color-primary);
    outline-offset: 2px;
}

/* Skip to Content Link */
.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: var(--color-primary);
    color: var(--color-text);
    padding: var(--spacing-xxs) var(--spacing-sm);
    z-index: 1000;
    transition: top var(--transition-duration) var(--transition-ease);
}

.skip-link:focus {
    top: var(--spacing-md);
}

/* =========================
    16. Additional Enhancements
========================= */

/* Card Component */
.card {
    background-color: var(--color-surface);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-elevation-1);
    transition: box-shadow var(--transition-duration), transform var(--transition-duration);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.card:hover {
    box-shadow: var(--shadow-elevation-2);
    transform: translateY(-4px); /* Increased translation for better effect */
}

/* Avatar Styles */
.avatar {
    width: 50px; /* Increased size for better visibility */
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--color-primary);
    transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.avatar:hover {
    transform: scale(1.05);
    box-shadow: var(--shadow-elevation-2);
}

/* =========================
    17. End of CSS
========================= */
