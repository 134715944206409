.inbox {
    background-color: #1a1a1a;
    color: #ffffff;
    padding: 20px;
  }
  
  .inbox h2 {
    color: #00ff00;
  }
  
  .inbox ul {
    list-style: none;
    padding: 0;
  }
  
  .inbox li {
    padding: 10px;
    border-bottom: 1px solid #333333;
    cursor: pointer;
  }
  
  .inbox li:hover {
    background-color: #333333;
  }
  