/* src/components/ComposeMessage.css */

.compose-message-page {
  padding: var(--spacing-lg);
  background-color: var(--color-background);
  min-height: calc(100vh - var(--top-bar-height));
  display: flex;
  justify-content: center;
  align-items: center;
}

.compose-message-container {
  background-color: var(--color-surface);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-elevation-1);
  width: 100%;
  max-width: 500px;
}

.compose-message-container h2 {
  margin-bottom: var(--spacing-md);
  color: var(--color-primary);
}

.error-message {
  color: var(--color-error);
  margin-bottom: var(--spacing-sm);
}

.compose-message-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-md);
}

.compose-message-form input,
.compose-message-form textarea {
  padding: var(--spacing-xs) var(--spacing-md);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  outline: none;
  transition: border-color var(--transition-duration);
}

.compose-message-form input:focus,
.compose-message-form textarea:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 5px var(--color-primary);
}

.receiver-input {
  background-color: var(--color-background);
  color: var(--color-text);
}

.compose-message-form button {
  padding: var(--spacing-xs) var(--spacing-md);
  background-color: var(--color-primary);
  border: none;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  color: var(--color-text);
  font-size: var(--font-size-sm);
  transition: background-color var(--transition-duration), transform var(--transition-duration);
}

.compose-message-form button:hover {
  background-color: var(--color-primary-variant);
  transform: translateY(-2px);
  box-shadow: var(--shadow-elevation-2);
}

.compose-message-form button:disabled {
  background-color: var(--color-border);
  cursor: not-allowed;
}
