/* =========================
   1. Reset and Basic Styles
========================= */

/* Reset and basic styles */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
.connectionRequestsPage {
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  scroll-behavior: smooth;
}

/* =========================
   2. CSS Variables for Theming
========================= */

:root {
  /* Color Palette */
  --color-background: #2B2B2B; /* Dark Grey */
  --color-surface: #3C3C3C; /* Solid Grey for surface */
  --color-primary: #C0C0C0; /* Silver */
  --color-primary-variant: #A8A8A8; /* Darker Silver */
  --color-secondary: #D3D3D3; /* Light Grey */
  --color-error: #CF6679; /* Retained for error messages */
  --color-text: #E0E0E0; /* Light Text */
  --color-text-secondary: #B3B3B3;
  --color-border: #555555; /* Grey Border */
  --color-hover: rgba(255, 255, 255, 0.1);
  --color-read: #00cc00; /* Green for read messages */
  --color-unread: #ff00ff; /* Magenta for unread sent messages */
  --color-unread-recipient: #cc0000; /* Red for unread by recipient */

  /* Spacing */
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-sm: 12px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 48px;

  /* Font Sizes */
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;

  /* Border Radius */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;

  /* Shadows */
  --shadow-elevation-1: 0px 1px 3px rgba(0, 0, 0, 0.2);
  --shadow-elevation-2: 0px 4px 6px rgba(0, 0, 0, 0.3);
  --shadow-elevation-3: 0px 10px 20px rgba(0, 0, 0, 0.4);

  /* Transition */
  --transition-duration: 0.3s;
  --transition-ease: ease-in-out;

  /* Z-Index */
  --z-index-modal: 1000;
}

/* =========================
   3. Connection Requests Page Styles
========================= */

/* Connection Requests Page Container */
.connectionRequestsPage {
  background: var(--color-surface);
  color: var(--color-text);
  padding: var(--spacing-lg);
  padding-top: calc(var(--top-bar-height) + var(--spacing-lg)); /* Added top padding */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn var(--transition-duration) var(--transition-ease);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-elevation-2);
  transition: background var(--transition-duration), box-shadow var(--transition-duration);
}

.connectionRequestsPage:hover {
  background: #4A4A4A; /* Solid darker grey on hover */
  box-shadow: var(--shadow-elevation-3);
}

/* Header Styles */
.connectionRequestsHeader {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the title */
  width: 100%;
  margin-bottom: var(--spacing-xl); /* Increased margin-bottom for more space */
}

.connectionRequestsHeader h1 {
  color: var(--color-secondary);
  font-size: var(--font-size-xxl);
  position: relative;
  padding-top: var(--spacing-sm);
  padding-bottom: var(--spacing-md); /* Added padding-bottom to accommodate underline */
}

.connectionRequestsHeader h1::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -var(--spacing-xs);
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--color-primary);
  border-radius: var(--border-radius-sm);
}

/* Loading Styles */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: var(--font-size-lg);
  color: var(--color-text-secondary);
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #C0C0C0; /* Silver color */
  animation: spin 1s ease infinite;
  margin-right: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Pending Requests List Styles */
.pendingRequestsList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md); /* Added margin-top for additional spacing */
}

.pendingRequestItem {
  background: var(--color-surface);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background var(--transition-duration), transform var(--transition-duration), box-shadow var(--transition-duration);
  cursor: pointer;
  box-shadow: var(--shadow-elevation-1);
}

.pendingRequestItem:hover {
  background: rgba(192, 192, 192, 0.1); /* Light metallic hover */
  transform: translateY(-2px);
  box-shadow: var(--shadow-elevation-2);
}

/* Requester Info Styles */
.requesterInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

/* Adjusted Avatar Styles */
.requesterAvatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--color-border);
  transition: border-color var(--transition-duration), transform var(--transition-duration);
}

.requesterAvatar:hover {
  border-color: var(--color-primary);
  transform: scale(1.05);
}

.requesterDetails h3 {
  font-size: var(--font-size-lg);
  color: var(--color-text);
  margin-bottom: 4px;
}

.requesterDetails p {
  font-size: var(--font-size-sm);
  color: var(--color-text-secondary);
}

.requestTimestamp {
  font-size: var(--font-size-xs);
  color: var(--color-text-secondary);
}

/* Request Actions Styles */
.requestActions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.approveButton,
.declineButton {
  padding: var(--spacing-xxs) var(--spacing-sm);
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: var(--font-size-sm);
  cursor: pointer;
  transition: background var(--transition-duration), transform var(--transition-duration);
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
}

.approveButton {
  background: var(--color-primary);
  color: var(--color-text);
}

.approveButton:hover {
  background: var(--color-primary-variant);
  transform: scale(1.05);
}

.declineButton {
  background: var(--color-error);
  color: var(--color-text);
}

.declineButton:hover {
  background: #ff1a1a; /* Darker red on hover */
  transform: scale(1.05);
}

/* =========================
   4. Animations
========================= */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(192, 192, 192, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0);
  }
}

/* =========================
   5. Responsive Styles
========================= */

/* Adjust container padding and header styles on tablets and smaller screens */
@media (max-width: 768px) {
  .connectionRequestsPage {
    padding: var(--spacing-md);
    padding-top: calc(var(--top-bar-height) + var(--spacing-md)); /* Maintain top padding */
  }

  .connectionRequestsHeader h1 {
    font-size: var(--font-size-xl); /* Reduced font size for tablets */
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-md); /* Ensure consistent padding */
  }

  .pendingRequestItem {
    padding: var(--spacing-sm);
  }

  .requesterAvatar {
    width: 50px; /* Slightly reduced avatar size for tablets */
    height: 50px;
  }

  .requesterDetails h3 {
    font-size: var(--font-size-md);
  }

  .requesterDetails p,
  .requestTimestamp {
    font-size: var(--font-size-xs);
  }

  .approveButton,
  .declineButton {
    font-size: var(--font-size-xs);
    padding: var(--spacing-xxs) var(--spacing-xs);
  }

  .requestActions {
    flex-direction: row;
    gap: var(--spacing-xs);
  }
}

/* Adjust avatar size, header styles, and container padding on mobile screens */
@media (max-width: 600px) {
  .connectionRequestsPage {
    padding: var(--spacing-sm);
    padding-top: calc(var(--top-bar-height) + var(--spacing-sm)); /* Adjust top padding */
  }

  .connectionRequestsHeader h1 {
    font-size: var(--font-size-lg); /* Further reduced font size for mobile */
    padding-top: var(--spacing-xxs);
    padding-bottom: var(--spacing-md); /* Ensure consistent padding */
  }

  .requesterAvatar {
    width: 40px; /* Further reduced avatar size for mobile screens */
    height: 40px;
  }

  .requesterInfo {
    gap: var(--spacing-xs);
  }

  .requesterDetails h3 {
    font-size: var(--font-size-md);
  }

  .requesterDetails p,
  .requestTimestamp {
    font-size: var(--font-size-xs);
  }

  .approveButton,
  .declineButton {
    font-size: var(--font-size-xs);
    padding: var(--spacing-xxs) var(--spacing-xs);
  }

  .requestActions {
    flex-direction: row;
    gap: var(--spacing-xs);
  }
}

/* Adjust layout and header styles on very small screens */
@media (max-width: 400px) {
  .connectionRequestsPage {
    padding: var(--spacing-xs);
    padding-top: calc(var(--top-bar-height) + var(--spacing-xs)); /* Adjust top padding */
  }

  .connectionRequestsHeader h1 {
    font-size: var(--font-size-md); /* Minimum readable font size */
    padding-top: var(--spacing-xxs);
    padding-bottom: var(--spacing-md); /* Ensure consistent padding */
  }

  .pendingRequestItem {
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-sm); /* Ensure consistent padding */
  }

  .requestActions {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--spacing-sm);
  }

  .approveButton,
  .declineButton {
    flex: 1;
    text-align: center;
  }

  .requesterAvatar {
    width: 35px; /* Further reduced avatar size for very small screens */
    height: 35px;
  }
}
