/* src/components/SharedFeed.css */

/* 1. CSS Variables for Consistency and Theming */
:root {
    /* Color Palette */
    --color-background: #1a1a1a;
    --color-primary: #00ff00;
    --color-secondary: #007bff;
    --color-accent: #ff69b4;
    --color-accent-hover: #ff1493;
    --color-muted: #555555;
    --color-error: #ff4d4d;
    --color-white: #ffffff;
    --color-gray-dark: #333333;
    --color-gray: #444444;
    --color-gray-light: #aaaaaa;
  
    /* Spacing */
    --spacing-small: 5px;
    --spacing-medium: 10px;
    --spacing-large: 15px;
    --spacing-xlarge: 20px;
  
    /* Font Sizes */
    --font-size-small: 10px;
    --font-size-medium: 12px;
    --font-size-large: 13px;
    --font-size-xlarge: 16px;
    --font-size-title: 1.8rem;
    --font-size-title-mobile: 1.4rem;
  
    /* Border Radius */
    --border-radius: 4px;
  
    /* Transition */
    --transition-speed: 0.3s;
    --transition-ease: ease;
  }
  
  /* 2. Global Styles */
  .shared-feed-page {
    padding: var(--spacing-xlarge);
    background-color: var(--color-background);
    color: var(--color-white);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-xlarge);
  }
  
  .header h1 {
    color: var(--color-primary);
    margin-left: var(--spacing-medium);
    font-size: var(--font-size-title);
  }
  
  /* 3. User Profile Section */
  .user-profile {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-large);
    background-color: var(--color-gray-dark);
    padding: var(--spacing-medium);
    border-radius: var(--border-radius);
    width: 100%;
    max-width: 600px;
  }
  
  .user-profile .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: var(--spacing-medium);
    object-fit: cover;
  }
  
  .user-profile .user-details {
    flex-grow: 1;
  }
  
  .user-profile .user-details h2 {
    margin: 0;
    font-size: var(--font-size-xlarge);
  }
  
  .user-profile .user-details p {
    margin: var(--spacing-small) 0 0 0;
    color: var(--color-gray-light);
  }
  
  /* 4. Feed Items Section */
  .feed-items {
    width: 100%;
    max-width: 600px;
  }
  
  .feed-item {
    background-color: var(--color-gray-dark);
    padding: var(--spacing-medium);
    margin-bottom: var(--spacing-small);
    border-radius: var(--border-radius);
    transition: background-color var(--transition-speed) var(--transition-ease),
                transform var(--transition-speed) var(--transition-ease);
  }
  
  .feed-item:hover {
    background-color: var(--color-gray);
    transform: scale(1.01);
  }
  
  .feed-header {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-small);
  }
  
  .feed-header .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: var(--spacing-small);
    object-fit: cover;
  }
  
  .feed-header .author-info h3 {
    margin: 0;
    font-size: var(--font-size-xlarge);
  }
  
  .feed-header .author-info .timestamp {
    margin: var(--spacing-small) 0 0 0;
    font-size: var(--font-size-medium);
    color: var(--color-gray-light);
  }
  
  .feed-content p {
    margin: 0 0 var(--spacing-small) 0;
    font-size: var(--font-size-large);
  }
  
  .feed-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: var(--border-radius);
    margin-top: var(--spacing-small);
  }
  
  .feed-actions {
    display: flex;
    gap: var(--spacing-small);
    margin-top: var(--spacing-small);
  }
  
  .feed-actions button {
    background-color: var(--color-primary);
    color: var(--color-background);
    border: none;
    padding: 6px 10px;
    border-radius: var(--border-radius);
    font-size: var(--font-size-medium);
    cursor: pointer;
    transition: background-color var(--transition-speed) var(--transition-ease),
                transform var(--transition-speed) var(--transition-ease);
  }
  
  .feed-actions button:hover {
    background-color: darken(var(--color-primary), 10%);
    transform: scale(1.05);
  }
  
  .feed-actions button:disabled {
    background-color: var(--color-muted);
    cursor: not-allowed;
  }
  
  /* 5. Loading and Error States */
  .loading,
  .error-message {
    font-size: var(--font-size-large);
    color: var(--color-gray-light);
  }
  
  /* 6. Responsive Design */
  @media (max-width: 768px) {
    .shared-feed-page {
      padding: var(--spacing-large) var(--spacing-medium);
    }
  
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header h1 {
      margin-left: 0;
      margin-top: var(--spacing-small);
      font-size: var(--font-size-title-mobile);
    }
  
    .user-profile {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .user-profile .avatar {
      margin-bottom: var(--spacing-small);
    }
  
    .feed-actions button {
      flex: 1;
    }
  }
  
  @media (max-width: 480px) {
    .header h1 {
      font-size: 1.2rem;
    }
  
    .feed-actions {
      flex-direction: column;
    }
  
    .feed-actions button {
      width: 100%;
      margin: var(--spacing-small) 0;
    }
  }
  